import React from 'react';
import { __ } from '../i18n';

export default class TermsAndPrivacy extends React.Component {
  render() {
    const { entityTypes } = this.props;
    return (
      <div className="terms">
        <h1 style={{ marginBottom: '38px' }}>Termos de Uso e Política de Privacidade</h1>
        <ul>
          {
            (entityTypes.includes('student') || entityTypes.includes('noentity')) &&
            <li><a href="#terms-student">TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA ALUNOS E RESPONSÁVEIS</a></li>
          }
          {
            entityTypes.includes('staff') &&
            <li><a href="#terms-staff">TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA FUNCIONÁRIO DA INSTITUIÇÃO DE ENSINO</a></li>
          }
          {
            entityTypes.includes('admin') &&
            <li><a href="#terms-admin">TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA ADMINISTRADOR DE SISTEMA</a></li>
          }

          {
            (entityTypes.includes('student') || entityTypes.includes('noentity')) &&
            <li><a href="#policy-student">POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CLASSAPP PARA ALUNOS E RESPONSÁVEIS (“POLÍTICA”)</a></li>
          }
          {
            (entityTypes.includes('staff') || entityTypes.includes('admin')) &&
            <li><a href="#policy-staff-admin">POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CLASSAPP PARA ADMINISTRADOR DE SISTEMA E FUNCIONÁRIO DA INSTITUIÇÃO DE ENSINO (“POLÍTICA”)</a></li>
          }
        </ul>

        {
          (entityTypes.includes('student') || entityTypes.includes('noentity')) &&
          <section>
            {!this.props.noHeader && <h2 id="terms-student" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA ALUNOS E RESPONSÁVEIS</h2>}

            <p style={{ textAlign: 'right' }}>Atualizada em 19 de julho de 2021.</p>
            <p>Bem-vindo(a) à <strong>ClassApp</strong>!</p>
            <p>A seguir são apresentados os Termos e Condições de Uso (“Termos”) contendo as principais regras que devem ser observadas por <strong>Você </strong>ao acessar e utilizar as funcionalidades disponíveis na área logada do site institucional, disponível no <em>link</em> &lt;www.classapp.com.br&gt; (“Portal ClassApp”), bem como do aplicativo (“Aplicativo ClassApp”), em conjunto denominados de “Nossos Ambientes”, pertencentes a:</p>

            <div style={{ border: '1px solid black', padding: '1rem', marginBottom: '2rem' }}>
              <p><strong>CLASSAPP SISTEMAS LTDA.</strong>, (“<strong>Nós</strong>”)</p>
              <p>Rua Carlos Gomes, 1321, 8º andar, sala 801 a 808, Centro, Limeira – SP, CEP: 13.480-013</p>
              <p>CNPJ: 21.560.411/0001-01</p>
            </div>

            <p>Como condição para acesso e uso das funcionalidades existentes em Nossos Ambientes, <strong>Você</strong> declara que fez a leitura completa e atenta das regras deste documento, estando plenamente ciente e de acordo com elas.</p>
            <p>Para acesso aos Nossos Ambientes, poderão ser requeridas determinadas informações sobre <strong>Você</strong>, com o intuito de garantir a segurança e a identificação no uso de Nossos Ambientes. Não se preocupe, a utilização de seus dados está regulada de acordo com nossa Política de Privacidade e Tratamento de Dados, que pode ser acessada por <strong>Você</strong> a qualquer momento.</p>

            <ol type="1">
              <li>
                <strong>DEFINIÇÕES</strong>
                <ol type="i">
                  <li><strong><em>AntiSpam</em>:</strong> Sistema que evita correspondências indesejadas, como publicidade em massa, pelo bloqueio de mensagem ou direcionamento destas para pastas específicas.</li>
                  <li><strong>Aplicativo ClassApp:</strong> Aplicativo disponibilizado aos alunos, pais, responsáveis legais, pedagógicos e financeiros, com a finalidade de proporcionar uma alternativa conveniente para que estes interajam com nossos conteúdos e utilizem as funcionalidades e serviços oferecidos, podendo ser baixado por meio do <em>App Store</em> e <em>Google Play</em>.</li>
                  <li><strong><em>Backup</em>:</strong> Salvaguarda de informações, realizada por meio da reprodução e/ou espelhamento de uma base de arquivos, com a finalidade de plena capacidade de recuperação em caso de incidente ou necessidade de <em>restore</em>, ou constituição de infraestrutura de acionamento imediato em caso de incidente ou necessidade justificada pelo Usuário.</li>
                  <li><strong>Canais de Atendimento:</strong> Meios de contato disponibilizados por <strong>Nós</strong>, para o envio de dúvidas e sugestões relacionadas aos Nossos Ambientes.</li>
                  <li><strong>ClassPay:</strong> Funcionalidade disponível no Portal ClassApp que permite o pagamento de despesas avulsas e esporádicas dos alunos por seus pais ou responsáveis legais e/ou financeiros.</li>
                  <li><strong>Conta de Acesso:</strong> Credencial de um Usuário que permite acesso à área restrita e às funcionalidades exclusivas existentes em Nossos Ambientes, definida pela combinação de <em>e-mail</em> ou número de celular e senha.</li>
                  <li><strong>Instituições de Ensino:</strong> Instituições de ensino clientes, que contratam nossos serviços para utilização por seus funcionários, representantes, terceiros, alunos e responsáveis por alunos.</li>
                  <li><strong><em>Layout</em>:</strong> Conjunto compreendido entre aparência, <em>design</em> e fluxos de Nossos Ambientes.</li>
                  <li><strong><em>Link</em>:</strong> Terminologia para endereço de <em>internet</em>.</li>
                  <li><strong><em>Login</em>:</strong> Processo para acessar um sistema informático restrito feito através da autenticação ou identificação do Usuário, usando credenciais previamente cadastradas no sistema.</li>
                  <li><strong>Matrículas e Financeiro:</strong> Quando disponível ao Usuário, possibilita a realização de matrículas, através da celebração de contrato por meio digital pelos responsáveis dos alunos, além do recebimento de cobranças automáticas.</li>
                  <li><strong>Portal ClassApp:</strong> Designa a área logada constante em nosso site institucional, disponível através do endereço eletrônico &lt;www.classapp.com.br&gt;.</li>
                  <li><strong>Usuários ou Você:</strong> Pessoas físicas que utilizam Nossos Ambientes através de perfis de acessos como “alunos” ou “responsáveis”. São os alunos, pais, representantes legais, pedagógicos e financeiros que desejam experimentar nossos serviços através de uma simulação ou que foram previamente cadastrados em nosso sistema pelas Instituições de Ensino para utilização de nossos serviços e funcionalidades disponíveis.</li>
                </ol>
              </li>

              <li>
                <strong>FUNCIONALIDADES DISPONÍVEIS EM NOSSOS AMBIENTES</strong>
                <ol type="1">
                  <li>
                    <strong>Restrição de acesso.</strong> As funcionalidades disponíveis em Nossos Ambientes são destinadas a alunos, pais, responsáveis legais, financeiros e pedagógicos, interessados em experimentar nossos serviços através de uma simulação ou previamente cadastrados em nosso sistema pelas Instituições de Ensino.
                    <ol type="1">
                      <li>Na eventualidade de Nossos Ambientes serem acessados por um menor de idade, os pais ou representantes legais deverão supervisionar e representar as crianças e adolescentes com idade inferior a 16 (dezesseis) anos e assistir os adolescentes em idade superior à 16 (dezesseis) anos e inferior à 18 (dezoito) anos, sempre que aplicável.</li>
                      <li><strong>Nós</strong> podemos recusar, não aprovar ou cancelar a criação de seu cadastro caso <strong>Você</strong> se utilize de dados incorretos ou falsos. O mesmo será aplicável durante a utilização de Nossos Ambientes, caso seja verificado seu uso de forma fraudulenta, bem como que tente ou viole estes Termos, ou quaisquer legislações vigentes.</li>
                      <li>Não obstante, caso seja verificada por <strong>Nós</strong> a existência de atividade suspeita ou transação que apresente considerável risco de fraude, <strong>Nós</strong> poderemos a qualquer tempo e a nosso exclusivo critério, solicitar informações adicionais a <strong>Você</strong>, sendo que, qualquer erro ou atraso no envio destas informações que gere prejuízo ou dano de qualquer natureza, será de sua inteira responsabilidade.</li>
                    </ol>
                  </li>
                  <li><strong>Elegibilidade.</strong> <strong>Nós</strong> podemos alterar os critérios de elegibilidade de acesso e utilização de Nossos Ambientes a qualquer momento e ao nosso critério, sem a necessidade de comunicação ou aviso prévio a <strong>Você</strong> ou a qualquer terceiro, sendo de extrema importância que <strong>Você</strong>, ao fazer uso das funcionalidades existentes em Nossos Ambientes, sempre verifique as regras vigentes.</li>
                  <li><strong><em>E-mails</em>.</strong> Ao fornecer seus dados para criação de uma Conta de Acesso em Nossos Ambientes, <strong>Você</strong> está ciente que, nos termos do previsto na Política de Privacidade e Tratamento de Dados, poderá receber, no <em>e-mail</em> e/ou telefone informados, mensagens com avisos e conteúdos publicitários enviados por <strong>Nós</strong>. Lembrando que, através dos Canais de Atendimento, a qualquer tempo, <strong>Você</strong> terá sempre a opção de suspender o recebimento destas comunicações, desde que não prejudique o contato imprescindível à resolução da solicitação realizada por <strong>Você</strong> em Nossos Ambientes.</li>
                  <li>
                    <strong>Apresentação de Nossos Ambientes.</strong> Nossos Ambientes e as funcionalidades neles existentes são apresentadas a <strong>Você</strong> na maneira como estão disponíveis no momento do acesso, mas podem passar por constantes aprimoramentos e atualizações sem a necessidade de prévio aviso.
                    <ol type="1">
                      <li>Neste caso, <strong>Nós</strong> nos comprometemos a preservar as funcionalidades de Nossos Ambientes com esforços razoáveis, utilizando <em>layout</em> que respeita a usabilidade e navegabilidade, sempre que possível, bem como a exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista uma correta percepção do que está sendo praticado e proteger, por meio do estado da técnica disponível, os dados coletados pelas funcionalidades disponibilizadas, garantindo seu sigilo.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>ACESSO AOS NOSSOS AMBIENTES</strong>
                <ol type="1">
                  <li>
                    <strong>Simulação em Nossos Ambientes.</strong> Para experimentar as funcionalidades disponíveis em Nossos Ambientes, <strong>Você</strong> poderá realizar a criação de uma Conta de Acesso, onde serão solicitadas informações que deverão ser prestadas unicamente por <strong>Você</strong>.
                    <ol type="1">
                      <li>Será necessário que <strong>Você</strong> informe: (i) seu endereço de <em>e-mail</em> e código de acesso enviado para sua caixa de entrada; ou (ii) seu número de celular e código de acesso enviado via SMS. Este código de acesso será gerado a cada vez que <strong>Você</strong> precisar acessar Nossos Ambientes e não possuir uma senha cadastrada.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Utilização efetiva de Nossos Ambientes.</strong> Para utilização das funcionalidades disponíveis em Nossos Ambientes de forma efetiva, <strong>Nós</strong> realizaremos o pré-cadastro de sua Conta de Acesso, mediante o compartilhamento de seus dados coletados pela Instituição de Ensino.
                    <ol type="1">
                      <li>Para ativação de sua Conta de Acesso, será necessário que <strong>Você</strong> adote os mesmos procedimentos descritos na cláusula 3.1.1. acima.</li>
                    </ol>
                  </li>
                  <li>Após seu primeiro acesso em Nossos Ambientes, será possível prosseguir com a criação de uma senha de acesso, a fim de garantir maior segurança aos seus dados.</li>
                  <li>Posteriormente ao seu acesso, será disponibilizada a <strong>Você</strong> a sua área logada, na qual <strong>Você</strong> poderá incluir uma foto de perfil de sua escolha.</li>
                  <li>Além dos dados acima, para prosseguir com a utilização dos serviços de pagamentos disponíveis no Portal ClassApp, será necessário que <strong>Você</strong> insira os dados de seu cartão de crédito, tais como (i) número; (ii) data de vencimento; (iii) nome que consta no cartão e (iv) código de verificação.</li>
                  <li><strong>Validação.</strong> As validações dos acessos realizados por <strong>Você</strong> são importantes para que <strong>Nós</strong> possamos manter a autenticidade da sua Conta de Acesso, prevenindo que esta seja utilizada por terceiros mal-intencionados, além de ser uma forma segura que permite a alteração de suas informações, acesso aos dados, utilização das funcionalidades, entre outros atos necessários no âmbito da navegação de Nossos Ambientes.</li>
                </ol>
              </li>

              <li>
                <strong>USO DE NOSSOS AMBIENTES POR VOCÊ</strong>
                <ol type="1">
                  <li><strong>Sigilo dos dados do cadastro.</strong> <strong>Você</strong> deverá manter o sigilo dos dados de sua Conta de Acesso em relação a terceiros e utilizá-los de modo individual e intransferível, não disponibilizando, divulgando ou compartilhando sua senha ou qualquer mecanismo de autenticação com quem quer que seja. Caso suspeite que a confidencialidade de sua senha foi violada, <strong>Você</strong> deverá proceder com sua troca ou atualização o mais rápido possível, além de nos comunicar através dos Canais de Atendimento, para que possam ser tomadas as devidas providências, quando aplicável.</li>
                  <li><strong>Você</strong> está ciente e reconhece que todos os acessos e operações realizadas em Nossos Ambientes após seu acesso bem-sucedido são interpretados como sendo de sua responsabilidade, de forma incontestável, inclusive aqueles derivados de uso indevido ou de divulgação deste para terceiros.</li>
                  <li>
                    <strong>Acesso aos Nossos Ambientes.</strong> Para acesso aos Nossos Ambientes, <strong>Você</strong> deve utilizar-se da <em>internet</em> e:
                    <ol type="i">
                      <li>Efetuar o acesso ao<em> link </em><a href="http://www.classapp.com.br" rel="noopener noreferrer" target="_blank">www.classapp.com.br</a>, para interagir no Portal ClassApp; ou</li>
                      <li>Realizar o <em>download</em>, através da <em>App Store </em>ou <em>Google Play</em>, ao seu dispositivo móvel, do Aplicativo ClassApp.</li>
                      <p>Não sendo permitido o acesso por nenhum outro meio diverso não autorizado por <strong>Nós</strong>.</p>
                    </ol>
                  </li>
                  <li>
                    <strong>Funcionalidades de Nossos Ambientes.</strong> Após a realização de sua identificação e autenticação através do <em>Login</em>, <strong>Você</strong> poderá utilizar Nossos Ambientes, onde poderá encontrar e ter acesso a diversas funcionalidades e conteúdos informativos criados para <strong>Você</strong>.
                    <ol type="1">
                      <li><strong>Funcionalidades disponíveis no Aplicativo ClassApp.</strong> As funcionalidades disponíveis no Aplicativo ClassApp têm, por objetivo, permitir que <strong>Você</strong>: (i) envie mensagens aos demais usuários, podendo esta conter áudios, arquivos e demais formatos de mídias; (ii) visualize postagens realizadas pelos demais usuários através da aba “Momentos”; (iii) acesse o calendário e visualize eventos; (iv) pesquise o histórico de conversas realizadas entre <strong>Você</strong> e demais usuários; e (v) remova a Conta de Acesso vinculada à <strong>Você</strong>.</li>
                      <li><strong>Funcionalidades disponíveis no Portal ClassApp.</strong> Além das opções descritas no item 4.4.1. acima, <strong>Você</strong> também encontrará disponíveis no Portal ClassApp as seguintes funcionalidades: (i) edição das informações relativas ao perfil do aluno; (ii) gestão das Contas de Acesso de Usuários vinculados ao perfil do aluno, podendo criar e excluir tais Contas, bem como atualizar suas informações; (iii) utilização da ferramenta ClassPay para realização de pagamentos à Instituição de Ensino; e (iv) quando disponível, acesso ao módulo Matrículas e Financeiro, para realização de matrícula e recebimento de cobranças automáticas.</li>
                      <li>Para utilização da funcionalidade de compartilhamento de mídias, <strong>Nós</strong> poderemos solicitar acesso ao seu áudio, câmera ou galeria de imagens. Caso não permita o acesso, o serviço não poderá ser executado.</li>
                    </ol>
                    <div style={{ border: '1px solid black', padding: '1rem', marginTop: '2rem', marginBottom: '2rem' }}>
                      <p style={{ marginTop: '0' }}><strong>ATENÇÃO!</strong> Eventuais contratos de matrículas e requisições de pagamentos feitas em Nossos Ambientes são de responsabilidade exclusiva da Instituição de Ensino, a qual é a única responsável por estabelecer as condições de venda, realização e pagamento de seus serviços. <strong>Nós</strong> não somos criadores, fornecedores ou tampouco proprietários dos serviços comercializados em Nossos Ambientes.</p>
                    </div>
                  </li>
                  <li>
                    <strong>Pagamentos.</strong> Para utilização das funcionalidades ClassPay e Matrículas e Financeiro, disponíveis no Portal ClassApp, <strong>Você</strong> poderá optar pela realização de pagamentos em benefício da Instituição de Ensino, através de boletos bancários ou lançamentos em cartão de crédito. As informações relativas ao seu cartão de crédito não serão armazenadas em Nossos Ambientes, sendo enviadas diretamente à empresa terceira responsável pela transação financeira.
                    <ol type="1">
                      <li><strong>Nós</strong> poderemos, a qualquer momento, incluir novos meios de pagamento ou limitar ou excluir os existentes, sem que isto implique na necessidade de prévio aviso a qualquer Usuário.</li>
                      <li>Com a aprovação dos valores apresentados, haverá a cobrança do valor previamente informado através do cartão de crédito selecionado ou da emissão de boleto bancário.</li>
                      <li>Eventuais erros de pagamento poderão ser reportados aos Canais de Atendimento. Note, entretanto, que há a possibilidade que alguns destes eventuais erros sejam dirimidos diretamente perante a instituição financeira pertinente, como, por exemplo, a emissora do cartão.</li>
                    </ol>
                    <div style={{ border: '1px solid black', padding: '1rem', marginTop: '2rem', marginBottom: '2rem' }}>
                      <p style={{ marginTop: '0' }}><strong>ATENÇÃO!</strong> As operações realizadas por meio da <em>internet </em>requerem cuidados e cautelas, uma vez que podem ser vulneráveis a ações de terceiros, portanto <strong>Você</strong> deve zelar para que todas as ações sejam realizadas em ambientes seguros com equipamentos e sistemas devidamente atualizados e protegidos.</p>
                    </div>
                  </li>
                  <li><strong>Notificações.</strong> <strong>Você</strong>, quando logado em Nossos Ambientes, receberá notificações a respeito de novas atividades vinculadas à sua Conta de Acesso.</li>
                  <li><strong>Enquetes.</strong> Quando da necessidade de resposta à enquete proposta pela Instituição de Ensino, <strong>Você</strong> reconhece e está de acordo que suas respostas fornecidas configurarão sua opinião e concordância com o assunto discutido, tendo validade e representando sua assinatura, nos termos do artigo 10, §2º da Medida Provisória 2.200-2/2001.</li>
                  <li>
                    <strong>Integridade das informações.</strong> <strong>Você</strong> é o único responsável pela integridade e veracidade das informações inseridas por <strong>Você</strong> em Nossos Ambientes, respondendo civil e criminalmente pelos efeitos advindos de uso irregular de informações de terceiros ou informações falsas.
                    <ol type="1">
                      <li><strong>Você</strong> compromete-se a, sempre que houver alteração de seus dados, a nos informar, através da área logada em Nossos Ambientes ou dos Canais de Atendimento, para fins de atualização.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>CONTEÚDO, DADOS SUBMETIDOS E PROPRIEDADE INTELECTUAL</strong>
                <ol type="1">
                  <li>
                    <strong>Conteúdo Informativo.</strong> Exceto se estabelecido em contrário por estes Termos, todas as informações, notícias, imagens, marcas e informações de mercado disponibilizados por <strong>Nós</strong> em Nossos Ambientes, em qualquer idioma ou forma, é de nossa exclusiva propriedade ou licença (“Conteúdo Informativo”). O uso de Nossos Ambientes por <strong>Você</strong> não gera qualquer presunção de transferência dessa propriedade ou licença. Por essa razão, <strong>Você</strong> não deverá comercializar, no todo ou em parte, o Conteúdo Informativo por quaisquer meios, onerosamente ou não, sabendo que se o fizer poderá ser penalizado civil e penalmente por <strong>Nós</strong> e/ou por qualquer terceiro titular do Conteúdo Informativo.
                    <ol type="1">
                      <li>Adicionalmente ao disposto acima, destacamos que as funcionalidades que compõem Nossos Ambientes são oferecidas na modalidade de prestação de serviços e licenciamento de <em>software</em>, não conferindo a <strong>Você</strong> nenhum direito sobre nosso <em>software</em> ou sobre suas estruturas de informática.</li>
                      <li>A eventual remoção, bloqueio ou suspensão de qualquer Conteúdo Informativo ou funcionalidade de Nossos Ambientes em decorrência de alguma reclamação deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais como reconhecimento de culpa ou de qualquer infração por <strong>Nós</strong> a direito de terceiro.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Dados e Informações Compartilhados.</strong> <strong>Você</strong> é e continuará sendo o titular e proprietário de todos os dados e informações submetidos e/ou compartilhados em Nossos Ambientes por <strong>Você</strong> (“Dados e Informações”), bem como será o exclusivo responsável por eles.
                    <ol type="1">
                      <li>Os Dados e Informações não constituirão nossa opinião, tampouco declaração de concordância com o que houver sido submetido e/ou compartilhado em Nossos Ambientes.</li>
                      <li>Todos os Dados e Informações submetidos e/ou compartilhados serão mantidos dentro de Nossos Ambientes, sendo vedado seu compartilhamento pelos demais usuários sem autorização.</li>
                    </ol>
                  </li>
                  <li><strong>Legalidade dos Dados e Informações.</strong> <strong>Nós</strong> não nos obrigaremos a processar ou tratar quaisquer dos Dados e Informações se houver razões para crer que tal processamento ou tratamento possa nos imputar qualquer infração de qualquer lei aplicável ou que Nossos Ambientes estejam sendo utilizados, sob nosso critério exclusivo, para quaisquer fins ilegais, ilícitos ou contrários à moralidade.</li>
                  <li>
                    <strong>Ausência de garantias quanto aos Dados e Informações e ao Conteúdo Informativo.</strong> <strong>Nós</strong> não garantimos a qualidade, coerência, integridade, forma, atualização ou fonte do Conteúdo Informativo ou dos Dados e Informações, pois não controlamos, verificamos ou fazemos juízo do Conteúdo Informativo, bem como dos Dados e Informações. <strong>Nós</strong> não somos responsáveis, portanto, por quaisquer inveracidade, desatualização ou erro do Conteúdo Informativo ou dos Dados e Informações, bem como não somos responsáveis por quaisquer danos oriundos do Conteúdo Informativo e dos Dados e Informações, a qualquer título.
                    <ol type="1">
                      <li><strong>Você</strong> fica ciente e concorda que <strong>Nós</strong> não possuímos qualquer obrigação real ou presumida de publicar qualquer Conteúdo Informativo ou Dados e Informações, bem como poderemos remover tal Conteúdo Informativo ou Dados e Informações com ou sem aviso prévio.</li>
                      <li>
                        Adicionalmente ao disposto na cláusula acima, <strong>Nós</strong> nos reservamos ao direito de não publicar ou excluir Dados e Informações que:
                        <ol type="i">
                          <li>Ofendam a honra, imagem, dignidade e reputação de terceiros;</li>
                          <li>Possuam conteúdos ligados à pornografia, pedofilia e matérias de cunho sexual;</li>
                          <li>Contenham conteúdos relacionados à racismo, discriminação de qualquer natureza, práticas de <em>bullying</em>, <em>stalking</em> ou qualquer outra espécie de constrangimento ilegal ou assédio;</li>
                          <li>Representem manifesta violação a direito autoral ou direito de imagem;</li>
                          <li>Instiguem ou façam apologia à prática de crimes de qualquer natureza;</li>
                          <li>Incentivem a prática de atos contrários à lei, à ordem pública e aos bons costumes;</li>
                          <li>Disseminem a ideia de uso da violência, ódio ou qualquer outra forma de agressão ao bem-estar físico de alguém;</li>
                          <li>Objetivem a realização de atividade comercial similar à nossa ou angariação de recursos por publicidade de terceiros, desviando-se da real intenção de nossos serviços; e</li>
                          <li>Demonstrem erros ou suspeitas de equívocos.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Armazenamento.</strong> <strong>Nós</strong> não prestamos e os Nossos Ambientes não constituem serviço de armazenamento, portanto <strong>Nós</strong> não possuímos a obrigação de armazenar, reter ou fornecer à <strong>Você</strong> ou a quaisquer terceiros cópia dos Dados e Informações ou qualquer Conteúdo Informativo.
                    <ol type="1">
                      <li>Não obstante, ressaltamos que é de sua inteira responsabilidade a realização de <em>backup</em> dos Dados e Informações relevantes para <strong>Você</strong>.</li>
                    </ol>
                  </li>
                  <li><strong>Não mineração.</strong> É vedada a utilização de <em>softwares</em> de mineração de dados de Nossos Ambientes, de qualquer tipo ou espécie, além de outro aqui não tipificado que atue de modo similar.</li>
                  <li><strong>Indenização.</strong> Na incidência de danos a <strong>Nós</strong> ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, inclusive aqueles cuja origem for de atos praticados por meio de seu acesso aos Nossos Ambientes, assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo nossa exclusão, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando-nos livre de prejuízos e ônus.</li>
                  <li><strong>Integridade de Nossos Ambientes.</strong> <strong>Você</strong> compromete-se a não acessar áreas de programação de Nossos Ambientes, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes ambientes, bem como não realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das funcionalidades de Nossos Ambientes.</li>
                </ol>
              </li>

              <li>
                <strong>ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</strong>
                <ol type="1">
                  <li>
                    <strong>Disponibilidade de Nossos Ambientes.</strong> <strong>Nós</strong> envidamos esforços para a manutenção da disponibilidade contínua e permanente de Nossos Ambientes. No entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos sistemas de comunicação e acesso à <em>internet</em>, ataques cibernético invasivos, ou quaisquer fatos de terceiro que fogem da esfera de nossa vigilância e responsabilidade.
                    <ol type="1">
                      <li>Se isso ocorrer, <strong>Nós</strong> faremos o que estiver ao nosso alcance para restabelecer o acesso aos Nossos Ambientes o mais breve possível, dentro das limitações técnicas de nossos serviços e serviços de terceiros, dos quais dependemos para ficar <em>online</em>. Por essa razão, <strong>Você</strong> fica ciente que não poderá pleitear indenização ou reparação de danos no caso de Nossos Ambientes permanecerem fora do ar, independente da motivação.</li>
                      <li>Eventuais procedimentos de manutenção que acarretem a indisponibilidade de Nossos Ambientes por longos períodos serão informados por meio de nossos canais oficiais.</li>
                      <li>
                        Sem prejuízo de outras condições destes Termos, <strong>Nós</strong> não nos responsabilizaremos:
                        <ol type="i">
                          <li>Por quaisquer problemas, <em>bugs</em>, <em>glitches</em> ou funcionamentos indevidos que ocorrerem nos seus dispositivos e equipamentos;</li>
                          <li>Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, a exemplo, mas não se limitando a ataque de <em>hackers</em>, falhas no sistema, no servidor ou na conexão à <em>internet</em>, inclusive por ações de <em>softwares</em> que possam, de algum modo, danificar ativos físicos ou lógicos em decorrência do acesso, utilização ou navegação em Nossos Ambientes, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos em Nossos Ambientes;</li>
                          <li>Pela navegação em quaisquer <em>links </em>externos disponibilizados em Nossos Ambientes, sendo seu dever, a leitura dos Termos e Condições de Uso e da Política de Privacidade do recurso acessado e agir conforme o determinado;</li>
                          <li>Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados disponibilizados em tais <em>links</em>, não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela visita de tais <em>websites</em>, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato; e</li>
                          <li>Por eventuais vazamentos de Dados e Informações não autorizados realizados por qualquer usuário.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>Os Dados e Informações submetidos e/ou compartilhados em Nossos Ambientes são armazenados em servidores seguros, de forma criptografada, com a utilização de medidas de segurança da informação constantemente atualizadas. Os Dados e Informações serão mantidos como confidenciais e serão adotadas todas as medidas possíveis contra perda, roubo, uso indevido, alteração e acesso não autorizado. Neste sentindo, destacados que <strong>Nós</strong> não nos responsabilizaremos por eventuais prejuízos que sejam decorrentes da divulgação de tais Dados e Informações por parte de terceiros que utilizem a rede pública ou a <em>internet</em>, subvertendo os sistemas de segurança para acessar as informações do Usuário.</li>
                </ol>
              </li>

              <li>
                <strong>RECOMENDAÇÕES DE SEGURANÇA</strong>
                <ol type="1">
                  <li>
                    <strong>Recomendações gerais.</strong> Para acessar Nossos Ambientes de forma segura e utilizar nossas funcionalidades de forma integral, é de inteira responsabilidade do Usuário dispor de dispositivo e equipamentos compatíveis, serviço de conexão à <em>internet</em> com antivírus e <em>firewall</em> habilitados e <em>softwares</em> devidamente atualizados – tais como navegadores e sistemas operacionais de dispositivos móveis – além da adoção de medidas de segurança mínima, como a adoção de uma senha forte para <em>Login</em> em sua Conta de Acesso.
                    <ol type="1">
                      <li>
                        Entende-se por senha forte aquela que:
                        <ol type="i">
                          <li>Possui ao menos 8 (oito) caracteres, sendo 1 (uma) letra maiúscula, 1 (uma) letra minúscula e 1 (um) número, símbolo ou caractere especial;</li>
                          <li>Não representa sequências numéricas relacionadas às informações pessoais ou de fácil dedução, tais como elementos do próprio nome, datas comemorativas ou repetição de caracteres iguais; e</li>
                          <li>Não é reutilizada em aplicativos, portais e plataformas de terceiros.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>É de sua responsabilidade deixar seus sistemas <em>AntiSpam</em>, filtros similares ou configurações de redirecionamento de mensagens ou notificações ajustados de modo que não interfiram no recebimento dos comunicados e materiais enviados pelos demais usuários através do uso de nossos serviços.</li>
                  <li>
                    <strong>Acesso ao Portal ClassApp.</strong> Sempre que acessar o Portal ClassApp, verifique se de fato está no ambiente disponibilizado por <strong>Nós</strong>, ações de <em>hackers</em> podem em uma tentativa de roubar seus dados replicarem uma cópia do Portal ClassApp ou áreas deste.
                    <ol type="1">
                      <li>Para verificar a integridade e veracidade do Portal ClassApp, clique no cadeado presente na barra de endereço do navegador, verificando os certificados presentes e informados.</li>
                      <li>Caso o navegador não apresente os certificados e/ou retorne com a mensagem de que o acesso não é seguro, interrompa imediatamente o uso do Portal ClassApp e nos comunique para que as devidas providências sejam tomadas.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Acesso não autorizado.</strong> <strong>Nós</strong> não seremos responsáveis por eventual acesso de terceiros não autorizados a sua Conta de Acesso, cabendo somente à <strong>Você</strong> a responsabilidade pela guarda de <em>login</em> e senha em local seguro, assim como o não compartilhamento destas informações com terceiros.
                    <ol type="1">
                      <li>Não nos caberá também a responsabilidade pelo acesso não autorizado proveniente de ataques realizados por <em>hackers</em> ou devido a ações de vírus que venham a acessar as suas informações e seu cadastro.</li>
                      <li>Caso <strong>Você</strong> constate ou desconfie que seu cadastro está sendo utilizado por terceiros não autorizados ou que tenha sido vítima de ataque de <em>hackers</em> ou de programas e/ou arquivos maliciosos (vírus), entre em contato imediatamente conosco através dos Canais de Atendimento, para que possam ser tomadas as devidas providências.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>PENALIDADES</strong>
                <ol type="1">
                  <li>
                    <strong>Penalidades.</strong> O descumprimento de qualquer cláusula destes Termos, poderá gerar: (i) suspensão temporária de Nossos Ambientes para seu <em>Login</em> e consequente impossibilidade de usufruir de Nossos Ambientes; (ii) bloqueio e exclusão de sua Conta de Acesso e/ou (iii) responsabilização nas esferas cível e penal.
                    <ol type="1">
                      <li><strong>Você</strong> reconhece desde já e compromete-se, durante a utilização de Nossos Ambientes, a não realizar quaisquer ações que atentem contra a moralidade, a ética e boa-fé, obrigando-se a em nenhuma hipótese divulgar, instigar ou auxiliar a prática de atos ilícitos ou fraudulentos, devendo combatê-los sempre que tomar conhecimento sob pena das previsões da cláusula 8.1 acima.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>OUTRAS DISPOSIÇÕES</strong>
                <ol type="1">
                  <li>
                    <strong>Canais de Atendimento.</strong> <strong>Você</strong> poderá utilizar os Canais de Atendimento disponibilizados em Nossos Ambientes toda vez que presenciar ou verificar conteúdo impróprio em Nossos Ambientes, seja ele notadamente ilícito ou contrário às regras de uso estipuladas nestes Termos.
                    <ol type="1">
                      <li>Eventual denúncia realizada em nossos Canais de Atendimento será sigilosa e preservará a sua identidade.</li>
                      <li>Buscamos agir de forma diligente e imediata nos casos de denúncias feitas por <strong>Você</strong> relacionadas à conteúdos que violem os princípios da ética, moral e bons costumes, que sejam ilícitos e que envolvam, especialmente, menores de idade.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Atualização dos Termos.</strong> Estes Termos estão sujeitos a constante melhoria e aprimoramento. Assim, <strong>Nós</strong> nos reservamos ao direito de modificá-los a qualquer momento, conforme sua finalidade ou conveniência, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, incluindo de órgãos reguladores, ou seja, <strong>Você</strong> deverá verificá-los sempre que efetuar o acesso em Nossos Ambientes.
                    <ol type="1">
                      <li>Ocorrendo atualizações nestes Termos, <strong>Nós</strong> notificaremos <strong>Você</strong> pelas ferramentas disponíveis em Nossos Ambientes ou pelos meios de contato fornecidos por <strong>Você</strong>.</li>
                      <li>Ao navegar por Nossos Ambientes e utilizar suas funcionalidades, <strong>Você</strong> aceita guiar-se pelos Termos que se encontram vigentes na data de acesso. Por isso, é recomendável que <strong>Você</strong> se atualize das condições vigentes.</li>
                    </ol>
                  </li>
                  <li><strong>Novação e renúncia.</strong> A tolerância do eventual descumprimento de quaisquer condições destes Termos não constituirá renúncia ou novação nem nos impedirá de exigir estas condições a qualquer tempo.</li>
                  <li><strong>Nulidade.</strong> Se alguma disposição destes Termos for julgada inaplicável ou sem efeito, o restante continuará a viger normalmente.</li>
                  <li><strong>Comunicação.</strong> <strong>Você</strong> reconhece que toda comunicação realizada por <em>e-mail</em> ou telefone (ao endereço de <em>e-mail</em> e número de telefone/celular informados no seu cadastro) é válida, eficaz e suficiente para a divulgação de qualquer assunto que se refira aos Nossos Ambientes.</li>
                  <li><strong>Data e hora.</strong> Nossos Ambientes possuem como base a data e horário oficial de Brasília (UTC/GMT -03:00).</li>
                  <li>
                    <strong>Lei aplicável e foro.</strong> Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro do seu domicílio para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
                    <ol type="1">
                      <li><strong>Você</strong>, caso não possua domicílio no Brasil, e em razão dos serviços oferecidos por <strong>Nós</strong> ser apenas em território nacional, se submete à legislação brasileira, concordando, portanto, que em havendo litígio a ser solucionado, a ação deverá ser proposta no Foro da Comarca de Limeira, Estado de São Paulo.</li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <br />
          </section>
        }
        {
          entityTypes.includes('staff') &&
          <div>
            {!this.props.noHeader && <h2 id="terms-staff" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA FUNCIONÁRIO DA INSTITUIÇÃO DE ENSINO</h2>}

            <p style={{ textAlign: 'right' }}>Atualizada em 19 de julho de 2021.</p>
            <p>Bem-vindo(a) à <strong>ClassApp</strong>!</p>
            <p>A seguir são apresentados os Termos e Condições de Uso (“Termos”) contendo as principais regras que devem ser observadas por <strong>Você </strong>ao acessar e utilizar as funcionalidades disponíveis na área logada do site institucional, disponível no <em>link</em> &lt;www.classapp.com.br&gt; (“Portal ClassApp”), bem como do aplicativo (“Aplicativo ClassApp”), em conjunto denominados de “Nossos Ambientes”, pertencentes a:</p>

            <div style={{ border: '1px solid black', padding: '1rem', marginBottom: '2rem' }}>
              <p><strong>CLASSAPP SISTEMAS LTDA.</strong>, (“<strong>Nós</strong>”)</p>
              <p>Rua Carlos Gomes, 1321, 8º andar, sala 801 a 808, Centro, Limeira – SP, CEP: 13.480-013</p>
              <p>CNPJ: 21.560.411/0001-01</p>
            </div>

            <p>Como condição para acesso e uso das funcionalidades existentes em Nossos Ambientes, <strong>Você</strong> declara que fez a leitura completa e atenta das regras deste documento, estando plenamente ciente e de acordo com elas.</p>
            <p>Para acesso aos Nossos Ambientes, poderão ser requeridas determinadas informações sobre <strong>Você</strong>, com o intuito de garantir a segurança e a identificação no uso de Nossos Ambientes. Não se preocupe, a utilização de seus dados está regulada de acordo com nossa Política de Privacidade e Tratamento de Dados, que pode ser acessada por <strong>Você</strong> a qualquer momento.</p>

            <ol type="1">
              <li>
                <strong>DEFINIÇÕES</strong>
                <ol type="i">
                  <li><strong><em>AntiSpam</em>:</strong> Sistema que evita correspondências indesejadas, como publicidade em massa, pelo bloqueio de mensagem ou direcionamento destas para pastas específicas.</li>
                  <li><strong>Aplicativo ClassApp:</strong> Aplicativo disponibilizado às Instituições de Ensino com a finalidade de proporcionar uma alternativa conveniente para que estas interajam com nossos conteúdos e utilizem as funcionalidades e serviços oferecidos, podendo ser baixado por meio do <em>App Store</em> e <em>Google Play</em>.</li>
                  <li><strong><em>Backup</em>:</strong> Salvaguarda de informações, realizada por meio da reprodução e/ou espelhamento de uma base de arquivos, com a finalidade de plena capacidade de recuperação em caso de incidente ou necessidade de <em>restore</em>, ou constituição de infraestrutura de acionamento imediato em caso de incidente ou necessidade justificada pelo Usuário ou Instituições de Ensino.</li>
                  <li><strong>Canais de Atendimento:</strong> Meios de contato disponibilizados por <strong>Nós</strong>, para o envio de dúvidas e sugestões relacionadas aos Nossos Ambientes.</li>
                  <li><strong>Conta de Acesso:</strong> Credencial de um Usuário que permite acesso à área restrita e às funcionalidades exclusivas existentes em Nossos Ambientes, definida pela combinação de <em>e-mail</em> ou número de celular e senha.</li>
                  <li><strong>Instituições de Ensino:</strong> Instituições de ensino clientes, que contratam nossos serviços para utilização por seus funcionários, representantes, terceiros, alunos e responsáveis por alunos.</li>
                  <li><strong><em>Layout</em>:</strong> Conjunto compreendido entre aparência, <em>design</em> e fluxos de Nossos Ambientes.</li>
                  <li><strong><em>Link</em>:</strong> Terminologia para endereço de <em>internet</em>.</li>
                  <li><strong><em>Login</em>:</strong> Processo para acessar um sistema informático restrito feito através da autenticação ou identificação do Usuário, usando credenciais previamente cadastradas no sistema.</li>
                  <li><strong>Portal ClassApp:</strong> Designa a área logada constante em nosso site institucional, disponível através do endereço eletrônico &lt;www.classapp.com.br&gt;.</li>
                  <li><strong>Usuários ou Você:</strong> Pessoas físicas, que desejam experimentar nossos serviços disponíveis em Nossos Ambientes através de uma simulação, utilizando-se o perfil funcionário professor ou pessoas autorizadas pelas Instituições de Ensino a utilizar Nossos Ambientes de forma efetiva, através do perfil de acesso como funcionário.</li>
                </ol>
              </li>

              <li>
                <strong>FUNCIONALIDADES DISPONÍVEIS EM NOSSOS AMBIENTES</strong>
                <ol type="1">
                  <li>
                    <strong>Restrição de acesso.</strong> As funcionalidades disponíveis em Nossos Ambientes são destinadas a funcionários interessados em experimentar nossos serviços através de uma simulação ou previamente cadastrados em nosso sistema pelas Instituições de Ensino.
                    <ol type="1">
                      <li><strong>Nós</strong> podemos recusar, não aprovar ou cancelar a criação de seu cadastro caso <strong>Você</strong> se utilize de dados incorretos ou falsos. O mesmo será aplicável durante a utilização de Nossos Ambientes, caso seja verificado seu uso de forma fraudulenta, bem como que tente ou viole estes Termos, ou quaisquer legislações vigentes.</li>
                      <li>Não obstante, caso seja verificada por <strong>Nós</strong> a existência de atividade suspeita ou transação que apresente considerável risco de fraude, <strong>Nós</strong> poderemos a qualquer tempo e a nosso exclusivo critério, solicitar informações adicionais a <strong>Você</strong>, sendo que, qualquer erro ou atraso no envio destas informações que gere prejuízo ou dano de qualquer natureza, será de sua inteira responsabilidade.</li>
                    </ol>
                  </li>
                  <li><strong>Elegibilidade.</strong> <strong>Nós</strong> podemos alterar os critérios de elegibilidade de acesso e utilização de Nossos Ambientes a qualquer momento e ao nosso critério, sem a necessidade de comunicação ou aviso prévio a <strong>Você</strong> ou a qualquer terceiro, sendo de extrema importância que <strong>Você</strong>, ao fazer uso das funcionalidades existentes em Nossos Ambientes, sempre verifique as regras vigentes.</li>
                  <li><strong><em>E-mails</em>.</strong> Ao fornecer seus dados para criação de uma Conta de Acesso em Nossos Ambientes, <strong>Você</strong> está ciente que, nos termos do previsto na Política de Privacidade e Tratamento de Dados, poderá receber, no <em>e-mail</em> e/ou telefone informados, mensagens com avisos e conteúdos publicitários enviados por <strong>Nós</strong>. Lembrando que, através dos Canais de Atendimento, a qualquer tempo, <strong>Você</strong> terá sempre a opção de suspender o recebimento destas comunicações, desde que não prejudique o contato imprescindível à resolução da solicitação realizada por <strong>Você</strong> em Nossos Ambientes.</li>
                  <li>
                    <strong>Apresentação de Nossos Ambientes.</strong> Nossos Ambientes e as funcionalidades neles existentes são apresentadas a <strong>Você</strong> na maneira como estão disponíveis no momento do acesso, mas podem passar por constantes aprimoramentos e atualizações sem a necessidade de prévio aviso.
                    <ol type="1">
                      <li>Neste caso, <strong>Nós</strong> nos comprometemos a preservar as funcionalidades de Nossos Ambientes com esforços razoáveis, utilizando <em>layout</em> que respeita a usabilidade e navegabilidade, sempre que possível, bem como a exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista uma correta percepção do que está sendo praticado e proteger, por meio do estado da técnica disponível, os dados coletados pelas funcionalidades disponibilizadas, garantindo seu sigilo.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>ACESSO AOS NOSSOS AMBIENTES</strong>
                <ol type="1">
                  <li>
                    <strong>Simulação em Nossos Ambientes.</strong> Para experimentar as funcionalidades disponíveis em Nossos Ambientes, <strong>Você</strong> poderá realizar a criação de uma Conta de Acesso, onde serão solicitadas informações que deverão ser prestadas unicamente por <strong>Você</strong>.
                    <ol type="1">
                      <li>Será necessário que <strong>Você</strong> informe: (i) seu endereço de <em>e-mail</em> e código de acesso enviado para sua caixa de entrada; ou (ii) seu número de celular e código de acesso enviado via SMS. Este código de acesso será gerado a cada vez que <strong>Você</strong> precisar acessar Nossos Ambientes e não possuir uma senha cadastrada.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Utilização efetiva de Nossos Ambientes.</strong> Para utilização das funcionalidades disponíveis em Nossos Ambientes de forma efetiva, <strong>Nós</strong> realizaremos o pré-cadastro de sua Conta de Acesso, mediante o compartilhamento de seus dados coletados pela Instituição de Ensino.
                    <ol type="1">
                      <li>Para ativação de sua Conta de Acesso, será necessário que <strong>Você</strong> adote os mesmos procedimentos descritos na cláusula 3.1.1. acima.</li>
                    </ol>
                  </li>
                  <li>
                    Após seu primeiro acesso em Nossos Ambientes, será possível prosseguir com a criação de uma senha de acesso, a fim de garantir maior segurança aos seus dados.
                    <ol type="1">
                      <li>Posteriormente ao seu acesso, será disponibilizada a <strong>Você</strong> a sua área logada, na qual <strong>Você</strong> poderá incluir uma foto de perfil de sua escolha.</li>
                    </ol>
                  </li>
                  <li><strong>Validação.</strong> As validações dos acessos realizados por <strong>Você</strong> são importantes para que <strong>Nós</strong> possamos manter a autenticidade da sua Conta de Acesso, prevenindo que esta seja utilizada por terceiros mal-intencionados, além de ser uma forma segura que permite a alteração de suas informações, acesso aos dados, utilização das funcionalidades, entre outros atos necessários no âmbito da navegação de Nossos Ambientes.</li>
                </ol>
              </li>

              <li>
                <strong>USO DE NOSSOS AMBIENTES POR VOCÊ</strong>
                <ol type="1">
                  <li><strong>Sigilo dos dados do cadastro.</strong> <strong>Você</strong> deverá manter o sigilo dos dados de sua Conta de Acesso em relação a terceiros e utilizá-los de modo individual e intransferível, não disponibilizando, divulgando ou compartilhando sua senha ou qualquer mecanismo de autenticação com quem quer que seja. Caso suspeite que a confidencialidade de sua senha foi violada, <strong>Você</strong> deverá proceder com sua troca ou atualização o mais rápido possível, além de nos comunicar através dos Canais de Atendimento, para que possam ser tomadas as devidas providências, quando aplicável.</li>
                  <li><strong>Você</strong> está ciente e reconhece que todos os acessos e operações realizadas em Nossos Ambientes após seu acesso bem-sucedido são interpretados como sendo de sua responsabilidade, de forma incontestável, inclusive aqueles derivados de uso indevido ou de divulgação deste para terceiros.</li>
                  <li>
                    <strong>Acesso aos Nossos Ambientes.</strong> Para acesso aos Nossos Ambientes, <strong>Você</strong> deve utilizar-se da <em>internet</em> e:
                    <ol type="i">
                      <li>Efetuar o acesso ao<em> link </em><a href="http://www.classapp.com.br" rel="noopener noreferrer" target="_blank">www.classapp.com.br</a>, para interagir no Portal ClassApp; ou</li>
                      <li>Realizar o <em>download</em>, através da <em>App Store </em>ou <em>Google Play</em>, ao seu dispositivo móvel, do Aplicativo ClassApp.</li>
                    </ol>
                    <p>Não sendo permitido o acesso por nenhum outro meio diverso não autorizado por <strong>Nós</strong>.</p>
                  </li>
                  <li>
                    <strong>Funcionalidades de Nossos Ambientes.</strong> Após a realização de sua identificação e autenticação através do <em>Login</em>, <strong>Você</strong> poderá utilizar Nossos Ambientes, onde poderá encontrar e ter acesso a diversas funcionalidades e conteúdos informativos criados para <strong>Você</strong>.
                    <ol type="1">
                      <li><strong>Funcionalidades disponíveis no Aplicativo ClassApp.</strong> As funcionalidades disponíveis no Aplicativo ClassApp têm, por objetivo, permitir que <strong>Você</strong>: (i) envie mensagens aos demais usuários, podendo esta conter formulários, cobranças de valores, enquetes, compromissos, relatórios, áudios, arquivos e demais formatos de mídias; (ii) efetue postagens e visualize aquelas realizadas pelos demais usuários através da aba “Momentos”; (iii) acesse o calendário e crie eventos; (iv) pesquise o histórico de conversas realizadas entre <strong>Você</strong> e demais usuários; (v) efetue o acompanhamento do <em>status</em> de utilização de nossos serviços por <strong>Você</strong>, através da aba “<em>Dashboard</em>”; e (vi) remova a Conta de Acesso vinculada à <strong>Você</strong>.</li>
                      <li><strong>Funcionalidades disponíveis no Portal ClassApp.</strong> Além das opções descritas no item 4.4.1. acima, <strong>Você</strong> também encontrará disponíveis no Portal ClassApp as seguintes funcionalidades: (i) edição de suas informações de perfil; (ii) gestão das Contas de Acesso de Usuários vinculados ao perfil do funcionário, podendo criar e excluir tais Contas, bem como atualizar suas informações; além de outras funcionalidades que poderão ser concedidas mediante permissão da Instituição de Ensino.</li>
                      <li>Para utilização da funcionalidade de compartilhamento de mídias, <strong>Nós</strong> poderemos solicitar acesso ao seu áudio, câmera ou galeria de imagens. Caso não permita o acesso, o serviço não poderá ser executado.</li>
                    </ol>
                  </li>
                  <li><strong>Notificações.</strong> <strong>Você</strong>, quando logado em Nossos Ambientes, receberá notificações a respeito de novas atividades vinculadas à sua Conta de Acesso.</li>
                  <li>
                    <strong>Integridade das informações.</strong> <strong>Você</strong> é o único responsável pela integridade e veracidade das informações inseridas por <strong>Você</strong> em Nossos Ambientes, respondendo civil e criminalmente pelos efeitos advindos de uso irregular de informações de terceiros ou informações falsas.
                    <ol type="1">
                      <li><strong>Você</strong> compromete-se a, sempre que houver alteração de seus dados, a nos informar, através da área logada em Nossos Ambientes ou dos Canais de Atendimento, para fins de atualização.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>CONTEÚDO, DADOS SUBMETIDOS E PROPRIEDADE INTELECTUAL</strong>
                <ol type="1">
                  <li>
                    <strong>Conteúdo Informativo.</strong> Exceto se estabelecido em contrário por estes Termos, todas as informações, notícias, imagens, marcas e informações de mercado disponibilizados por <strong>Nós</strong> em Nossos Ambientes, em qualquer idioma ou forma, é de nossa exclusiva propriedade ou licença (“Conteúdo Informativo”). O uso de Nossos Ambientes por <strong>Você</strong> não gera qualquer presunção de transferência dessa propriedade ou licença. Por essa razão, <strong>Você</strong> não deverá comercializar, no todo ou em parte, o Conteúdo Informativo por quaisquer meios, onerosamente ou não, sabendo que se o fizer poderá ser penalizado civil e penalmente por <strong>Nós</strong> e/ou por qualquer terceiro titular do Conteúdo Informativo.
                    <ol type="1">
                      <li>Adicionalmente ao disposto acima, destacamos que as funcionalidades que compõem Nossos Ambientes são oferecidas na modalidade de prestação de serviços e licenciamento de <em>software</em>, não conferindo a <strong>Você</strong> nenhum direito sobre nosso <em>software</em> ou sobre suas estruturas de informática.</li>
                      <li>A eventual remoção, bloqueio ou suspensão de qualquer Conteúdo Informativo ou funcionalidade de Nossos Ambientes em decorrência de alguma reclamação deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais como reconhecimento de culpa ou de qualquer infração por <strong>Nós</strong> a direito de terceiro.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Dados e Informações Compartilhados.</strong> <strong>Você</strong> é e continuará sendo o titular e proprietário de todos os dados e informações submetidos e/ou compartilhados em Nossos Ambientes por <strong>Você</strong> (“Dados e Informações”), bem como será o exclusivo responsável por eles.
                    <ol type="1">
                      <li>Os Dados e Informações não constituirão nossa opinião, tampouco declaração de concordância com o que houver sido submetido e/ou compartilhado em Nossos Ambientes.</li>
                      <li>Todos os Dados e Informações submetidos e/ou compartilhados serão mantidos dentro de Nossos Ambientes, sendo vedado seu compartilhamento pelos demais usuários sem autorização.</li>
                    </ol>
                  </li>
                  <li><strong>Legalidade dos Dados e Informações.</strong> <strong>Nós</strong> não nos obrigaremos a processar ou tratar quaisquer dos Dados e Informações se houver razões para crer que tal processamento ou tratamento possa nos imputar qualquer infração de qualquer lei aplicável ou que Nossos Ambientes estejam sendo utilizados, sob nosso critério exclusivo, para quaisquer fins ilegais, ilícitos ou contrários à moralidade.</li>
                  <li>
                    <strong>Ausência de garantias quanto aos Dados e Informações e ao Conteúdo Informativo.</strong> <strong>Nós</strong> não garantimos a qualidade, coerência, integridade, forma, atualização ou fonte do Conteúdo Informativo ou dos Dados e Informações, pois não controlamos, verificamos ou fazemos juízo do Conteúdo Informativo, bem como dos Dados e Informações. <strong>Nós</strong> não somos responsáveis, portanto, por quaisquer inveracidade, desatualização ou erro do Conteúdo Informativo ou dos Dados e Informações, bem como não somos responsáveis por quaisquer danos oriundos do Conteúdo Informativo e dos Dados e Informações, a qualquer título.
                    <ol type="1">
                      <li><strong>Você</strong> fica ciente e concorda que <strong>Nós</strong> não possuímos qualquer obrigação real ou presumida de publicar qualquer Conteúdo Informativo ou Dados e Informações, bem como poderemos remover tal Conteúdo Informativo ou Dados e Informações com ou sem aviso prévio.</li>
                      <li>Adicionalmente ao disposto na cláusula acima, <strong>Nós</strong> nos reservamos ao direito de não publicar ou excluir Dados e Informações que:</li>
                      <ol type="i">
                        <li>Ofendam a honra, imagem, dignidade e reputação de terceiros;</li>
                        <li>Possuam conteúdos ligados à pornografia, pedofilia e matérias de cunho sexual;</li>
                        <li>Contenham conteúdos relacionados à racismo, discriminação de qualquer natureza, práticas de <em>bullying</em>, <em>stalking</em> ou qualquer outra espécie de constrangimento ilegal ou assédio;</li>
                        <li>Representem manifesta violação a direito autoral ou direito de imagem;</li>
                        <li>Instiguem ou façam apologia à prática de crimes de qualquer natureza;</li>
                        <li>Incentivem a prática de atos contrários à lei, à ordem pública e aos bons costumes;</li>
                        <li>Disseminem a ideia de uso da violência, ódio ou qualquer outra forma de agressão ao bem-estar físico de alguém;</li>
                        <li>Objetivem a realização de atividade comercial similar à nossa ou angariação de recursos por publicidade de terceiros, desviando-se da real intenção de nossos serviços; e</li>
                        <li>Demonstrem erros ou suspeitas de equívocos.</li>
                      </ol>
                    </ol>
                  </li>
                  <li>
                    <strong>Armazenamento.</strong> <strong>Nós</strong> não prestamos e os Nossos Ambientes não constituem serviço de armazenamento, portanto <strong>Nós</strong> não possuímos a obrigação de armazenar, reter ou fornecer à <strong>Você</strong> ou a quaisquer terceiros cópia dos Dados e Informações ou qualquer Conteúdo Informativo.
                    <ol type="1">
                      <li>Não obstante, ressaltamos que é de sua inteira responsabilidade a realização de <em>backup</em> dos Dados e Informações relevantes para <strong>Você</strong>.</li>
                    </ol>
                  </li>
                  <li><strong>Não mineração.</strong> É vedada a utilização de <em>softwares</em> de mineração de dados de Nossos Ambientes, de qualquer tipo ou espécie, além de outro aqui não tipificado que atue de modo similar.</li>
                  <li><strong>Indenização.</strong> Na incidência de danos a <strong>Nós</strong> ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, inclusive aqueles cuja origem for de atos praticados por meio de seu acesso aos Nossos Ambientes, assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo nossa exclusão, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando-nos livre de prejuízos e ônus.</li>
                  <li><strong>Integridade de Nossos Ambientes.</strong> <strong>Você</strong> compromete-se a não acessar áreas de programação de Nossos Ambientes, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes ambientes, bem como não realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das funcionalidades de Nossos Ambientes.</li>
                </ol>
              </li>

              <li>
                <strong>ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</strong>
                <ol type="1">
                  <li>
                    <strong>Disponibilidade de Nossos Ambientes.</strong> <strong>Nós</strong> envidamos esforços para a manutenção da disponibilidade contínua e permanente de Nossos Ambientes. No entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos sistemas de comunicação e acesso à <em>internet</em>, ataques cibernético invasivos, ou quaisquer fatos de terceiro que fogem da esfera de nossa vigilância e responsabilidade.
                    <ol type="1">
                      <li>Se isso ocorrer, <strong>Nós</strong> faremos o que estiver ao nosso alcance para restabelecer o acesso aos Nossos Ambientes o mais breve possível, dentro das limitações técnicas de nossos serviços e serviços de terceiros, dos quais dependemos para ficar <em>online</em>. Por essa razão, <strong>Você</strong> fica ciente que não poderá pleitear indenização ou reparação de danos no caso de Nossos Ambientes permanecerem fora do ar, independente da motivação.</li>
                      <li>Eventuais procedimentos de manutenção que acarretem a indisponibilidade de Nossos Ambientes por longos períodos serão informados por meio de nossos canais oficiais.</li>
                      <li>
                        Sem prejuízo de outras condições destes Termos, <strong>Nós</strong> não nos responsabilizaremos:
                        <ol type="i">
                          <li>Por quaisquer problemas, <em>bugs</em>, <em>glitches</em> ou funcionamentos indevidos que ocorrerem nos seus dispositivos e equipamentos;</li>
                          <li>Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, a exemplo, mas não se limitando a ataque de <em>hackers</em>, falhas no sistema, no servidor ou na conexão à <em>internet</em>, inclusive por ações de <em>softwares</em> que possam, de algum modo, danificar ativos físicos ou lógicos em decorrência do acesso, utilização ou navegação em Nossos Ambientes, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos em Nossos Ambientes;</li>
                          <li>Pela navegação em quaisquer <em>links </em>externos disponibilizados em Nossos Ambientes, sendo seu dever, a leitura dos Termos e Condições de Uso e da Política de Privacidade do recurso acessado e agir conforme o determinado;</li>
                          <li>Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados disponibilizados em tais <em>links</em>, não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela visita de tais <em>websites</em>, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato; e</li>
                          <li>Por eventuais vazamentos de Dados e Informações não autorizados realizados por qualquer usuário.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>Os Dados e Informações submetidos e/ou compartilhados em Nossos Ambientes são armazenados em servidores seguros, de forma criptografada, com a utilização de medidas de segurança da informação constantemente atualizadas. Os Dados e Informações serão mantidos como confidenciais e serão adotadas todas as medidas possíveis contra perda, roubo, uso indevido, alteração e acesso não autorizado. Neste sentindo, destacamos que <strong>Nós</strong> não nos responsabilizaremos por eventuais prejuízos que sejam decorrentes da divulgação de tais Dados e Informações por parte de terceiros que utilizem a rede pública ou a <em>internet</em>, subvertendo os sistemas de segurança para acessar as informações do Usuário.</li>
                </ol>
              </li>

              <li>
                <strong>RECOMENDAÇÕES DE SEGURANÇA</strong>
                <ol type="1">
                  <li>
                    <strong>Recomendações gerais.</strong> Para acessar Nossos Ambientes de forma segura e utilizar nossas funcionalidades de forma integral, é de inteira responsabilidade do Usuário dispor de dispositivo e equipamentos compatíveis, serviço de conexão à <em>internet</em> com antivírus e <em>firewall</em> habilitados e <em>softwares</em> devidamente atualizados – tais como navegadores e sistemas operacionais de dispositivos móveis – além da adoção de medidas de segurança mínima, como a adoção de uma senha forte para <em>Login</em> em sua Conta de Acesso.
                    <ol type="1">
                      <li>
                        Entende-se por senha forte aquela que:
                        <ol type="i">
                          <li>Possui ao menos 8 (oito) caracteres, sendo 1 (uma) letra maiúscula, 1 (uma) letra minúscula e 1 (um) número, símbolo ou caractere especial;</li>
                          <li>Não representa sequências numéricas relacionadas às informações pessoais ou de fácil dedução, tais como elementos do próprio nome, datas comemorativas ou repetição de caracteres iguais; e</li>
                          <li>Não é reutilizada em aplicativos, portais e plataformas de terceiros.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>É de sua responsabilidade deixar seus sistemas <em>AntiSpam</em>, filtros similares ou configurações de redirecionamento de mensagens ou notificações ajustados de modo que não interfiram no recebimento dos comunicados e materiais enviados pelos demais usuários através do uso de nossos serviços.</li>
                  <li>
                    <strong>Acesso ao Portal ClassApp.</strong> Sempre que acessar o Portal ClassApp, verifique se de fato está no ambiente disponibilizado por <strong>Nós</strong>, ações de <em>hackers</em> podem em uma tentativa de roubar seus dados replicarem uma cópia do Portal ClassApp ou áreas deste.
                    <ol type="1">
                      <li>Para verificar a integridade e veracidade do Portal ClassApp, clique no cadeado presente na barra de endereço do navegador, verificando os certificados presentes e informados.</li>
                      <li>Caso o navegador não apresente os certificados e/ou retorne com a mensagem de que o acesso não é seguro, interrompa imediatamente o uso do Portal ClassApp e nos comunique para que as devidas providências sejam tomadas.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Acesso não autorizado.</strong> <strong>Nós</strong> não seremos responsáveis por eventual acesso de terceiros não autorizados a sua Conta de Acesso, cabendo somente à <strong>Você</strong> a responsabilidade pela guarda de <em>login</em> e senha em local seguro, assim como o não compartilhamento destas informações com terceiros.
                    <ol type="1">
                      <li>Não nos caberá também a responsabilidade pelo acesso não autorizado proveniente de ataques realizados por <em>hackers</em> ou devido a ações de vírus que venham a acessar as suas informações e seu cadastro.</li>
                      <li>Caso <strong>Você</strong> constate ou desconfie que seu cadastro está sendo utilizado por terceiros não autorizados ou que tenha sido vítima de ataque de <em>hackers</em> ou de programas e/ou arquivos maliciosos (vírus), entre em contato imediatamente conosco através dos Canais de Atendimento, para que possam ser tomadas as devidas providências.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>PENALIDADES</strong>
                <ol type="1">
                  <li>
                    <strong>Penalidades.</strong> O descumprimento de qualquer cláusula destes Termos, poderá gerar: (i) suspensão temporária de Nossos Ambientes para seu <em>Login</em> e consequente impossibilidade de usufruir de Nossos Ambientes; (ii) bloqueio e exclusão de sua Conta de Acesso e/ou (iii) responsabilização nas esferas cível e penal.
                    <ol type="1">
                      <li><strong>Você</strong> reconhece desde já e compromete-se, durante a utilização de Nossos Ambientes, a não realizar quaisquer ações que atentem contra a moralidade, a ética e boa-fé, obrigando-se a em nenhuma hipótese divulgar, instigar ou auxiliar a prática de atos ilícitos ou fraudulentos, devendo combatê-los sempre que tomar conhecimento sob pena das previsões da cláusula 8.1 acima.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>OUTRAS DISPOSIÇÕES</strong>
                <ol type="1">
                  <li>
                    <strong>Canais de Atendimento.</strong> <strong>Você</strong> poderá utilizar os Canais de Atendimento disponibilizados em Nossos Ambientes toda vez que presenciar ou verificar conteúdo impróprio em Nossos Ambientes, seja ele notadamente ilícito ou contrário às regras de uso estipuladas nestes Termos.
                    <ol type="1">
                      <li>Eventual denúncia realizada em nossos Canais de Atendimento será sigilosa e preservará a sua identidade.</li>
                      <li>Buscamos agir de forma diligente e imediata nos casos de denúncias feitas por <strong>Você</strong> relacionadas à conteúdos que violem os princípios da ética, moral e bons costumes, que sejam ilícitos e que envolvam, especialmente, menores de idade.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Atualização dos Termos.</strong> Estes Termos estão sujeitos a constante melhoria e aprimoramento. Assim, <strong>Nós</strong> nos reservamos ao direito de modificá-los a qualquer momento, conforme sua finalidade ou conveniência, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, incluindo de órgãos reguladores, ou seja, <strong>Você</strong> deverá verificá-los sempre que efetuar o acesso em Nossos Ambientes.
                    <ol type="1">
                      <li>Ocorrendo atualizações nestes Termos, <strong>Nós</strong> notificaremos <strong>Você</strong> pelas ferramentas disponíveis em Nossos Ambientes ou pelos meios de contato fornecidos por <strong>Você</strong>.</li>
                      <li>Ao navegar por Nossos Ambientes e utilizar suas funcionalidades, <strong>Você</strong> aceita guiar-se pelos Termos que se encontram vigentes na data de acesso. Por isso, é recomendável que <strong>Você</strong> se atualize das condições vigentes.</li>
                    </ol>
                  </li>
                  <li><strong>Novação e renúncia.</strong> A tolerância do eventual descumprimento de quaisquer condições destes Termos não constituirá renúncia ou novação nem nos impedirá de exigir estas condições a qualquer tempo.</li>
                  <li><strong>Nulidade.</strong> Se alguma disposição destes Termos for julgada inaplicável ou sem efeito, o restante continuará a viger normalmente.</li>
                  <li><strong>Comunicação.</strong> <strong>Você</strong> reconhece que toda comunicação realizada por <em>e-mail</em> ou telefone (ao endereço de <em>e-mail</em> e número de telefone/celular informados no seu cadastro) é válida, eficaz e suficiente para a divulgação de qualquer assunto que se refira aos Nossos Ambientes.</li>
                  <li><strong>Data e hora.</strong> Nossos Ambientes possuem como base a data e horário oficial de Brasília (UTC/GMT -03:00).</li>
                  <li><strong>Lei aplicável e foro.</strong> Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro da Comarca de Limeira, Estado de São Paulo, para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.</li>
                </ol>
              </li>
            </ol>
            <br />
          </div >
        }
        {
          entityTypes.includes('admin') &&
          <section>
            {!this.props.noHeader && <h2 id="terms-admin" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>TERMOS E CONDIÇÕES DE USO DA CLASSAPP PARA ADMINISTRADOR DE SISTEMA</h2>}
            <p style={{ textAlign: 'right' }}>Atualizada em 19 de julho de 2021.</p>
            <p>Bem-vindo(a) à <strong>ClassApp</strong>!</p>
            <p>A seguir são apresentados os Termos e Condições de Uso (“Termos”) contendo as principais regras que devem ser observadas por <strong>Você </strong>ao acessar e utilizar as funcionalidades disponíveis na área logada do site institucional, disponível no <em>link</em> &lt;www.classapp.com.br&gt; (“Portal ClassApp”), bem como do aplicativo (“Aplicativo ClassApp”), em conjunto denominados de “Nossos Ambientes”, pertencentes a:</p>

            <div style={{ border: '1px solid black', padding: '1rem', marginBottom: '2rem' }}>
              <p><strong>CLASSAPP SISTEMAS LTDA.</strong>, (“<strong>Nós</strong>”)</p>
              <p>Rua Carlos Gomes, 1321, 8º andar, sala 801 a 808, Centro, Limeira – SP, CEP: 13.480-013</p>
              <p>CNPJ: 21.560.411/0001-01</p>
            </div>

            <p>Como condição para acesso e uso das funcionalidades existentes em Nossos Ambientes, <strong>Você</strong> declara que fez a leitura completa e atenta das regras deste documento, estando plenamente ciente e de acordo com elas.</p>
            <p>Para acesso aos Nossos Ambientes, poderão ser requeridas determinadas informações sobre <strong>Você</strong>, com o intuito de garantir a segurança e a identificação no uso de Nossos Ambientes. Não se preocupe, a utilização de seus dados está regulada de acordo com nossa Política de Privacidade e Tratamento de Dados, que pode ser acessada por <strong>Você</strong> a qualquer momento.</p>

            <ol type="1">
              <li>
                <strong>DEFINIÇÕES</strong>
                <ol type="i">
                  <li><strong>Administrador/Gerenciador de Sistema:</strong> Usuário designado pelas Instituições de Ensino, com permissão administrativa para atuar em Nossos Ambientes, tendo acesso, controle e capacidade de gerenciamento de todas as informações relativas às Instituições de Ensino.</li>
                  <li><strong><em>AntiSpam</em>:</strong> Sistema que evita correspondências indesejadas, como publicidade em massa, pelo bloqueio de mensagem ou direcionamento destas para pastas específicas.</li>
                  <li><strong>Aplicativo ClassApp:</strong> Aplicativo disponibilizado às Instituições de Ensino com a finalidade de proporcionar uma alternativa conveniente para que estas interajam com nossos conteúdos e utilizem as funcionalidades e serviços oferecidos, podendo ser baixado por meio do <em>App Store</em> e <em>Google Play</em>.</li>
                  <li><strong><em>Backup</em>:</strong> Salvaguarda de informações, realizada por meio da reprodução e/ou espelhamento de uma base de arquivos, com a finalidade de plena capacidade de recuperação em caso de incidente ou necessidade de <em>restore</em>, ou constituição de infraestrutura de acionamento imediato em caso de incidente ou necessidade justificada pelo Usuário ou Instituições de Ensino.</li>
                  <li><strong>Canais de Atendimento:</strong> Meios de contato disponibilizados por <strong>Nós</strong>, para o envio de dúvidas e sugestões relacionadas aos Nossos Ambientes.</li>
                  <li><strong>ClassPay:</strong> Funcionalidade disponível no Portal ClassApp que permite a gestão de receitas e cobranças realizadas aos responsáveis pelos alunos das Instituições de Ensino, referentes às despesas avulsas e esporádicas; além de possibilitar o recebimento destes pagamentos pelas Instituições de Ensino.</li>
                  <li><strong>Conta de Acesso:</strong> Credencial de um Usuário que permite acesso à área restrita e às funcionalidades exclusivas existentes em Nossos Ambientes, definida pela combinação de <em>e-mail</em> ou número de celular e senha.</li>
                  <li><strong>Instituições de Ensino:</strong> Instituições de ensino clientes, que contratam nossos serviços para utilização por seus funcionários, representantes, terceiros, alunos e responsáveis por alunos.</li>
                  <li><strong><em>Layout</em>:</strong> Conjunto compreendido entre aparência, <em>design</em> e fluxos de Nossos Ambientes.</li>
                  <li><strong><em>Link</em>:</strong> Terminologia para endereço de <em>internet</em>.</li>
                  <li><strong><em>Login</em>:</strong> Processo para acessar um sistema informático restrito feito através da autenticação ou identificação do Usuário, usando credenciais previamente cadastradas no sistema.</li>
                  <li><strong>Matrículas e Financeiro:</strong> Módulo adicional, o qual possibilita que as Instituições de Ensino realizem campanhas de matrícula, através do aceite de contrato pelos responsáveis dos alunos, permitindo o recebimento de valores pré-determinados, de forma automática, pelas Instituições de Ensino.</li>
                  <li><strong>Portal ClassApp:</strong> Designa a área logada constante em nosso site institucional, disponível através do endereço eletrônico &lt;www.classapp.com.br&gt;.</li>
                  <li><strong>Usuários ou Você:</strong> Pessoas físicas, autorizadas pelas Instituições de Ensino a utilizar Nossos Ambientes, através do perfis de acesso como Administrador/Gerenciador de Sistema.</li>
                </ol>
              </li>

              <li>
                <strong>FUNCIONALIDADES DISPONÍVEIS EM NOSSOS AMBIENTES</strong>
                <ol type="1">
                  <li>
                    <strong>Restrição de acesso.</strong> As funcionalidades disponíveis em Nossos Ambientes para Usuários com perfis de Administrador de Sistema são destinadas exclusivamente a pessoas físicas maiores de 18 (dezoito) anos, indicadas exclusivamente pelas Instituições de Ensino.
                    <ol type="1">
                      <li><strong>Nós</strong> podemos recusar, não aprovar ou cancelar a criação de seu cadastro caso <strong>Você</strong> se utilize de dados incorretos ou falsos. O mesmo será aplicável durante a utilização de Nossos Ambientes, caso seja verificado seu uso de forma fraudulenta, bem como que tente ou viole estes Termos, ou quaisquer legislações vigentes.</li>
                      <li>Não obstante, caso seja verificada por <strong>Nós</strong> a existência de atividade suspeita ou transação que apresente considerável risco de fraude, <strong>Nós</strong> poderemos a qualquer tempo e a nosso exclusivo critério, solicitar informações adicionais a <strong>Você</strong>, sendo que, qualquer erro ou atraso no envio destas informações que gere prejuízo ou dano de qualquer natureza, será de sua inteira responsabilidade.</li>
                    </ol>
                  </li>
                  <li><strong>Elegibilidade.</strong> <strong>Nós</strong> podemos alterar os critérios de elegibilidade de acesso e utilização de Nossos Ambientes a qualquer momento e ao nosso critério, sem a necessidade de comunicação ou aviso prévio a <strong>Você</strong> ou a qualquer terceiro, sendo de extrema importância que <strong>Você</strong>, ao fazer uso das funcionalidades existentes em Nossos Ambientes, sempre verifique as regras vigentes.</li>
                  <li><strong><em>E-mails</em>.</strong> Ao fornecer seus dados para criação de uma Conta de Acesso em Nossos Ambientes, <strong>Você</strong> está ciente que, nos termos do previsto na Política de Privacidade e Tratamento de Dados, poderá receber, no <em>e-mail</em> e/ou telefone informados, mensagens com avisos e conteúdos publicitários enviados por <strong>Nós</strong>. Lembrando que, através dos Canais de Atendimento, a qualquer tempo, <strong>Você</strong> terá sempre a opção de suspender o recebimento destas comunicações, desde que não prejudique o contato imprescindível à resolução da solicitação realizada por <strong>Você</strong> em Nossos Ambientes.</li>
                  <li>
                    <strong>Apresentação de Nossos Ambientes.</strong> Nossos Ambientes e as funcionalidades neles existentes são apresentadas a <strong>Você</strong> na maneira como estão disponíveis no momento do acesso, mas podem passar por constantes aprimoramentos e atualizações sem a necessidade de prévio aviso.
                    <ol type="1">
                      <li>Neste caso, <strong>Nós</strong> nos comprometemos a preservar as funcionalidades de Nossos Ambientes com esforços razoáveis, utilizando <em>layout</em> que respeita a usabilidade e navegabilidade, sempre que possível, bem como a exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista uma correta percepção do que está sendo praticado e proteger, por meio do estado da técnica disponível, os dados coletados pelas funcionalidades disponibilizadas, garantindo seu sigilo, de modo que estes sejam acessados apenas por <strong>Nós</strong>, por <strong>Você</strong> e demais usuários que você autorizar.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>ACESSO AOS NOSSOS AMBIENTES</strong>
                <ol type="1">
                  <li><strong>Informações Necessárias.</strong> Para utilizar as funcionalidades disponíveis em Nossos Ambientes, <strong>Você</strong> deverá realizar a criação de sua Conta de Acesso, onde serão solicitadas informações que deverão ser prestadas unicamente por <strong>Você</strong>.</li>
                  <li>
                    Será necessário que <strong>Você</strong> informe: (i) seu endereço de <em>e-mail</em> e código de acesso enviado para sua caixa de entrada; ou (ii) seu número de celular e código de acesso enviado via SMS. Este código de acesso será gerado a cada vez que <strong>Você</strong> precisar acessar Nossos Ambientes e não possuir uma senha cadastrada.
                    <ol type="1">
                      <li>Após seu primeiro acesso em Nossos Ambientes, será possível prosseguir com a criação de uma senha de acesso, a fim de garantir maior segurança aos seus dados.</li>
                      <li>Posteriormente ao seu acesso, será disponibilizada a <strong>Você</strong> a sua área logada, na qual <strong>Você</strong> poderá incluir uma foto de perfil de sua escolha.</li>
                    </ol>
                  </li>
                  <li><strong>Validação.</strong> As validações dos acessos realizados por <strong>Você</strong> são importantes para que <strong>Nós</strong> possamos manter a autenticidade da sua Conta de Acesso, prevenindo que esta seja utilizada por terceiros mal-intencionados, além de ser uma forma segura que permite a alteração de suas informações, acesso aos dados, utilização das funcionalidades, entre outros atos necessários no âmbito da navegação de Nossos Ambientes.</li>
                </ol>
              </li>

              <li>
                <strong>USO DE NOSSOS AMBIENTES POR VOCÊ</strong>
                <ol type="1">
                  <li><strong>Sigilo dos dados do cadastro.</strong> <strong>Você</strong> deverá manter o sigilo dos dados de sua Conta de Acesso em relação a terceiros e utilizá-los de modo individual e intransferível, não disponibilizando, divulgando ou compartilhando sua senha ou qualquer mecanismo de autenticação com quem quer que seja. Caso suspeite que a confidencialidade de sua senha foi violada, <strong>Você</strong> deverá proceder com sua troca ou atualização o mais rápido possível, além de nos comunicar através dos Canais de Atendimento, para que possam ser tomadas as devidas providências, quando aplicável.</li>
                  <li><strong>Você</strong> está ciente e reconhece que todos os acessos e operações realizadas em Nossos Ambientes após seu acesso bem-sucedido são interpretados como sendo de sua responsabilidade, de forma incontestável, inclusive aqueles derivados de uso indevido ou de divulgação deste para terceiros.</li>
                  <li>
                    <strong>Acesso aos Nossos Ambientes.</strong> Para acesso aos Nossos Ambientes, <strong>Você</strong> deve utilizar-se da <em>internet</em> e:
                    <ol type="i">
                      <li>Efetuar o acesso ao<em> link </em><a href="http://www.classapp.com.br" rel="noopener noreferrer" target="_blank">www.classapp.com.br</a>, para interagir no Portal ClassApp; ou</li>
                      <li>Realizar o <em>download</em>, através da <em>App Store </em>ou <em>Google Play</em>, ao seu dispositivo móvel, do Aplicativo ClassApp.</li>
                    </ol>
                  </li>
                  <p>Não sendo permitido o acesso por nenhum outro meio diverso não autorizado por <strong>Nós</strong>.</p>
                  <li>
                    <strong>Funcionalidades de Nossos Ambientes.</strong> Após a realização de sua identificação e autenticação através do <em>Login</em>, <strong>Você</strong> poderá utilizar Nossos Ambientes, onde poderá encontrar e ter acesso a diversas funcionalidades e conteúdos informativos criados para <strong>Você</strong>.
                    <ol type="1">
                      <li><strong>Funcionalidades disponíveis no Aplicativo ClassApp.</strong> As funcionalidades disponíveis no Aplicativo ClassApp têm, por objetivo, permitir que <strong>Você</strong>: (i) envie mensagens aos demais usuários, podendo esta conter formulários, cobranças de valores, enquetes, compromissos, relatórios, áudios, arquivos e demais formatos de mídias; (ii) efetue postagens e visualize aquelas realizadas pelos demais usuários através da aba “Momentos”; (iii) acesse o calendário e crie eventos; (iv) pesquise o histórico de conversas realizadas entre <strong>Você</strong> e demais usuários; (v) efetue o acompanhamento do <em>status</em> de utilização de nossos serviços pela Instituição de Ensino a qual <strong>Você</strong> está vinculado, através da aba “<em>Dashboard</em>”; e (vi) remova a Conta de Acesso vinculada à <strong>Você</strong>.</li>
                      <li><strong>Funcionalidades disponíveis no Portal ClassApp.</strong> Além das opções descritas no item 4.4.1. acima, <strong>Você</strong> também encontrará disponíveis no Portal ClassApp as seguintes funcionalidades: (i) edição de suas informações de perfil; (ii) gestão de Contas de Acesso de alunos, responsáveis e funcionários da Instituição de Ensino, podendo criar e excluir tais Contas, bem como editar suas permissões; (iii) gestão de grupos e canais de comunicação relevantes à Instituição de Ensino; (iv) visualização de todo o histórico de conversas realizadas entre funcionários e alunos e/ou responsáveis; (v) criação e gerência de formulários e relatórios; (vi) utilização da ferramenta ClassPay para gestão de receitas e cobranças realizadas pela Instituição de Ensino; (vii) visualização e gestão de integrações de sistemas realizadas, solicitadas pela Instituição de Ensino a <strong>Nós</strong>; (viii) gerência de <em>links</em> disponibilizados aos usuários; (ix) configuração do perfil pertencente à Instituição de Ensino, possibilitando sua atualização bem como a personalização das permissões concedidas às categorias de usuários vinculados a esta; e (x) acesso ao módulo adicional Matrículas e Financeiro, quando contratado pela Instituição de Ensino a qual <strong>Você</strong> pertence, para realização de campanhas de matrícula e cobranças de valores automáticos, de acordo com as configurações determinadas por <strong>Você</strong>.</li>
                      <li>Para utilização da funcionalidade de compartilhamento de mídias, <strong>Nós</strong> poderemos solicitar acesso ao seu áudio, câmera ou galeria de imagens. Caso não permita o acesso, o serviço não poderá ser executado.</li>
                      <li>Para utilização da funcionalidade ClassPay, <strong>Você</strong> deverá, através da aba “Bancos e Saques”, inserir determinados dados da Instituição de Ensino, como: (i) razão social ou nome fantasia; (ii) contato financeiro responsável; (iii) dados financeiros, tais como: banco, agência e número da conta bancária; (iv) dados do titular da conta bancária cadastrada (CPF ou CNPJ, nome e endereço). Ainda, <strong>Você</strong> deverá configurar o modelo de absorção de taxas que pretende adotar, bem como o formato de saques (automático ou manual).</li>
                    </ol>
                  </li>
                  <li><strong>Notificações.</strong> <strong>Você</strong>, quando logado em Nossos Ambientes, receberá notificações a respeito de novas atividades vinculadas à sua Conta de Acesso.</li>
                  <li>
                    <strong>Integridade das informações.</strong> <strong>Você</strong> é o único responsável pela integridade e veracidade das informações inseridas por <strong>Você</strong> em Nossos Ambientes, respondendo civil e criminalmente pelos efeitos advindos de uso irregular de informações de terceiros ou informações falsas.
                    <ol type="1">
                      <li><strong>Você</strong> compromete-se a, sempre que houver alteração de seus dados, a nos informar, através da área logada em Nossos Ambientes ou dos Canais de Atendimento, para fins de atualização.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>CONTEÚDO, DADOS SUBMETIDOS E PROPRIEDADE INTELECTUAL</strong>
                <ol type="1">
                  <li>
                    <strong>Conteúdo Informativo.</strong> Exceto se estabelecido em contrário por estes Termos, todas as informações, notícias, imagens, marcas e informações de mercado disponibilizados por <strong>Nós</strong> em Nossos Ambientes, em qualquer idioma ou forma, é de nossa exclusiva propriedade ou licença (“Conteúdo Informativo”). O uso de Nossos Ambientes por <strong>Você</strong> não gera qualquer presunção de transferência dessa propriedade ou licença. Por essa razão, <strong>Você</strong> não deverá comercializar, no todo ou em parte, o Conteúdo Informativo por quaisquer meios, onerosamente ou não, sabendo que se o fizer poderá ser penalizado civil e penalmente por <strong>Nós</strong> e/ou por qualquer terceiro titular do Conteúdo Informativo.
                    <ol type="1">
                      <li>Adicionalmente ao disposto acima, destacamos que as funcionalidades que compõe Nossos Ambientes são oferecidas na modalidade de prestação de serviços e licenciamento de <em>software</em>, não conferindo a <strong>Você</strong> nenhum direito sobre nosso <em>software</em> ou sobre suas estruturas de informática.</li>
                      <li>A eventual remoção, bloqueio ou suspensão de qualquer Conteúdo Informativo ou funcionalidade de Nossos Ambientes em decorrência de alguma reclamação deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais como reconhecimento de culpa ou de qualquer infração por <strong>Nós</strong> a direito de terceiro.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Dados e Informações Compartilhados.</strong> <strong>Você</strong> é e continuará sendo o titular e proprietário de todos os dados e informações submetidos e/ou compartilhados em Nossos Ambientes por <strong>Você</strong> (“Dados e Informações”), bem como será o exclusivo responsável por eles.
                    <ol type="1">
                      <li>Os Dados e Informações não constituirão nossa opinião, tampouco declaração de concordância com o que houver sido submetido e/ou compartilhado em Nossos Ambientes.</li>
                      <li>Todos os Dados e Informações submetidos e/ou compartilhados serão mantidos dentro de Nossos Ambientes, sendo vedado seu compartilhamento pelos demais usuários sem autorização.</li>
                    </ol>
                  </li>
                  <li><strong>Legalidade dos Dados e Informações.</strong> <strong>Nós</strong> não nos obrigaremos a processar ou tratar quaisquer dos Dados e Informações se houver razões para crer que tal processamento ou tratamento possa nos imputar qualquer infração de qualquer lei aplicável ou que Nossos Ambientes estejam sendo utilizados, sob nosso critério exclusivo, para quaisquer fins ilegais, ilícitos ou contrários à moralidade.</li>
                  <li>
                    <strong>Ausência de garantias quanto aos Dados e Informações e ao Conteúdo Informativo.</strong> <strong>Nós</strong> não garantimos a qualidade, coerência, integridade, forma, atualização ou fonte do Conteúdo Informativo ou dos Dados e Informações, pois não controlamos, verificamos ou fazemos juízo do Conteúdo Informativo, bem como dos Dados e Informações. <strong>Nós</strong> não somos responsáveis, portanto, por quaisquer inveracidade, desatualização ou erro do Conteúdo Informativo ou dos Dados e Informações, bem como não somos responsáveis por quaisquer danos oriundos do Conteúdo Informativo e dos Dados e Informações, a qualquer título.
                    <ol type="1">
                      <li><strong>Você</strong> fica ciente e concorda que <strong>Nós</strong> não possuímos qualquer obrigação real ou presumida de publicar qualquer Conteúdo Informativo ou Dados e Informações, bem como poderemos remover tal Conteúdo Informativo ou Dados e Informações com ou sem aviso prévio.</li>
                      <li>
                        Adicionalmente ao disposto na cláusula acima, <strong>Nós</strong> nos reservamos ao direito de não publicar ou excluir Dados e Informações que:
                        <ol type="i">
                          <li>Ofendam a honra, imagem, dignidade e reputação de terceiros;</li>
                          <li>Possuam conteúdos ligados à pornografia, pedofilia e matérias de cunho sexual;</li>
                          <li>Contenham conteúdos relacionados à racismo, discriminação de qualquer natureza, práticas de <em>bullying</em>, <em>stalking</em> ou qualquer outra espécie de constrangimento ilegal ou assédio;</li>
                          <li>Representem manifesta violação a direito autoral ou direito de imagem;</li>
                          <li>Instiguem ou façam apologia à prática de crimes de qualquer natureza;</li>
                          <li>Incentivem a prática de atos contrários à lei, à ordem pública e aos bons costumes;</li>
                          <li>Disseminem a ideia de uso da violência, ódio ou qualquer outra forma de agressão ao bem-estar físico de alguém;</li>
                          <li>Objetivem a realização de atividade comercial similar à nossa ou angariação de recursos por publicidade de terceiros, desviando-se da real intenção de nossos serviços; e</li>
                          <li>Demonstrem erros ou suspeitas de equívocos.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Armazenamento.</strong> <strong>Nós</strong> não prestamos e os Nossos Ambientes não constituem serviço de armazenamento, portanto <strong>Nós</strong> não possuímos a obrigação de armazenar, reter ou fornecer à <strong>Você</strong> ou a quaisquer terceiros cópia dos Dados e Informações ou qualquer Conteúdo Informativo.
                    <ol type="1">
                      <li> Não obstante, ressaltamos que é de sua inteira responsabilidade a realização de <em>backup</em> dos Dados e Informações relevantes para <strong>Você</strong>.</li>
                    </ol>
                  </li>
                  <li><strong>Não mineração.</strong> É vedada a utilização de <em>softwares</em> de mineração de dados de Nossos Ambientes, de qualquer tipo ou espécie, além de outro aqui não tipificado que atue de modo similar.</li>
                  <li><strong>Indenização.</strong> Na incidência de danos a <strong>Nós</strong> ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, inclusive aqueles cuja origem for de atos praticados por meio de seu acesso aos Nossos Ambientes, assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo nossa exclusão, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando-nos livre de prejuízos e ônus.</li>
                  <li><strong>Integridade de Nossos Ambientes.</strong> <strong>Você</strong> compromete-se a não acessar áreas de programação de Nossos Ambientes, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes ambientes, bem como não realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das funcionalidades de Nossos Ambientes.</li>
                </ol>
              </li>

              <li>
                <strong>ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</strong>
                <ol type="1">
                  <li>
                    <strong>Disponibilidade de Nossos Ambientes.</strong> <strong>Nós</strong> envidamos esforços para a manutenção da disponibilidade contínua e permanente de Nossos Ambientes. No entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos sistemas de comunicação e acesso à <em>internet</em>, ataques cibernético invasivos, ou quaisquer fatos de terceiro que fogem da esfera de nossa vigilância e responsabilidade.
                    <ol type="1">
                      <li>Se isso ocorrer, <strong>Nós</strong> faremos o que estiver ao nosso alcance para restabelecer o acesso aos Nossos Ambientes o mais breve possível, dentro das limitações técnicas de nossos serviços e serviços de terceiros, dos quais dependemos para ficar <em>online</em>. Por essa razão, <strong>Você</strong> fica ciente que não poderá pleitear indenização ou reparação de danos no caso de Nossos Ambientes permanecerem fora do ar, independente da motivação.</li>
                      <li>Eventuais procedimentos de manutenção que acarretem a indisponibilidade de Nossos Ambientes por longos períodos serão informados por meio de nossos canais oficiais.</li>
                      <li>
                        Sem prejuízo de outras condições destes Termos, <strong>Nós</strong> não nos responsabilizaremos:
                        <ol type="i">
                          <li>Por quaisquer problemas, <em>bugs</em>, <em>glitches</em> ou funcionamentos indevidos que ocorrerem nos seus dispositivos e equipamentos;</li>
                          <li>Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, a exemplo, mas não se limitando a ataque de <em>hackers</em>, falhas no sistema, no servidor ou na conexão à <em>internet</em>, inclusive por ações de <em>softwares</em> que possam, de algum modo, danificar ativos físicos ou lógicos em decorrência do acesso, utilização ou navegação em Nossos Ambientes, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos em Nossos Ambientes;</li>
                          <li>Pela navegação em quaisquer <em>links </em>externos disponibilizados em Nossos Ambientes, sendo seu dever, a leitura dos Termos e Condições de Uso e da Política de Privacidade do recurso acessado e agir conforme o determinado;</li>
                          <li>Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados disponibilizados em tais <em>links</em>, não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela visita de tais <em>websites</em>, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato; e</li>
                          <li>Por eventuais vazamentos de Dados e Informações não autorizados realizados por qualquer usuário.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>Os Dados e Informações submetidos e/ou compartilhados em Nossos Ambientes são armazenados em servidores seguros, de forma criptografada, com a utilização de medidas de segurança da informação constantemente atualizadas. Os Dados e Informações serão mantidos como confidenciais e serão adotadas todas as medidas possíveis contra perda, roubo, uso indevido, alteração e acesso não autorizado. Neste sentindo, destacados que <strong>Nós</strong> não nos responsabilizaremos por eventuais prejuízos que sejam decorrentes da divulgação de tais Dados e Informações por parte de terceiros que utilizem a rede pública ou a <em>internet</em>, subvertendo os sistemas de segurança para acessar as informações do Usuário.</li>
                </ol>
              </li>

              <li>
                <strong>RECOMENDAÇÕES DE SEGURANÇA</strong>
                <ol type="1">
                  <li>
                    <strong>Recomendações gerais.</strong> Para acessar Nossos Ambientes de forma segura e utilizar nossas funcionalidades de forma integral, é de inteira responsabilidade do Usuário dispor de dispositivo e equipamentos compatíveis, serviço de conexão à <em>internet</em> com antivírus e <em>firewall</em> habilitados e <em>softwares</em> devidamente atualizados – tais como navegadores e sistemas operacionais de dispositivos móveis – além da adoção de medidas de segurança mínima, como a adoção de uma senha forte para <em>Login</em> em sua Conta de Acesso.
                    <ol type="1">
                      <li>
                        Entende-se por senha forte aquela que:
                        <ol type="i">
                          <li>Possui ao menos 8 (oito) caracteres, sendo 1 (uma) letra maiúscula, 1 (uma) letra minúscula e 1 (um) número, símbolo ou caractere especial;</li>
                          <li>Não representa sequências numéricas relacionadas às informações pessoais ou de fácil dedução, tais como elementos do próprio nome, datas comemorativas ou repetição de caracteres iguais; e</li>
                          <li>Não é reutilizada em aplicativos, portais e plataformas de terceiros.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>É de sua responsabilidade deixar seus sistemas <em>AntiSpam</em>, filtros similares ou configurações de redirecionamento de mensagens ou notificações ajustados de modo que não interfiram no recebimento dos comunicados e materiais enviados pelos demais usuários através do uso de nossos serviços.</li>
                  <li>
                    <strong>Acesso ao Portal ClassApp.</strong> Sempre que acessar o Portal ClassApp, verifique se de fato está no ambiente disponibilizado por <strong>Nós</strong>, ações de <em>hackers</em> podem em uma tentativa de roubar seus dados replicarem uma cópia do Portal ClassApp ou áreas deste.
                    <ol type="1">
                      <li>Para verificar a integridade e veracidade do Portal ClassApp, clique no cadeado presente na barra de endereço do navegador, verificando os certificados presentes e informados.</li>
                      <li>Caso o navegador não apresente os certificados e/ou retorne com a mensagem de que o acesso não é seguro, interrompa imediatamente o uso do Portal ClassApp e nos comunique para que as devidas providências sejam tomadas.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Acesso não autorizado.</strong> <strong>Nós</strong> não seremos responsáveis por eventual acesso de terceiros não autorizados a sua Conta de Acesso, cabendo somente à <strong>Você</strong> a responsabilidade pela guarda de <em>login</em> e senha em local seguro, assim como o não compartilhamento destas informações com terceiros.
                    <ol type="1">
                      <li>Não nos caberá também a responsabilidade pelo acesso não autorizado proveniente de ataques realizados por <em>hackers</em> ou devido a ações de vírus que venham a acessar as suas informações e seu cadastro.</li>
                      <li>Caso <strong>Você</strong> constate ou desconfie que seu cadastro está sendo utilizado por terceiros não autorizados ou que tenha sido vítima de ataque de <em>hackers</em> ou de programas e/ou arquivos maliciosos (vírus), entre em contato imediatamente conosco através dos Canais de Atendimento, para que possam ser tomadas as devidas providências.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>PENALIDADES</strong>
                <ol type="1">
                  <li>
                    <strong>Penalidades.</strong> O descumprimento de qualquer cláusula destes Termos, poderá gerar: (i) suspensão temporária de Nossos Ambientes para seu <em>Login</em> e consequente impossibilidade de usufruir de Nossos Ambientes; (ii) bloqueio e exclusão de sua Conta de Acesso e/ou (iii) responsabilização nas esferas cível e penal.
                    <ol type="1">
                      <li><strong>Você</strong> reconhece desde já e compromete-se, durante a utilização de Nossos Ambientes, a não realizar quaisquer ações que atentem contra a moralidade, a ética e boa-fé, obrigando-se a em nenhuma hipótese divulgar, instigar ou auxiliar a prática de atos ilícitos ou fraudulentos, devendo combatê-los sempre que tomar conhecimento sob pena das previsões da cláusula 8.1 acima.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>OUTRAS DISPOSIÇÕES</strong>
                <ol type="1">
                  <li>
                    <strong>Canais de Atendimento.</strong> <strong>Você</strong> poderá utilizar os Canais de Atendimento disponibilizados em Nossos Ambientes toda vez que presenciar ou verificar conteúdo impróprio em Nossos Ambientes, seja ele notadamente ilícito ou contrário às regras de uso estipuladas nestes Termos.
                    <ol type="1">
                      <li>Eventual denúncia realizada em nossos Canais de Atendimento será sigilosa e preservará a sua identidade.</li>
                      <li>Buscamos agir de forma diligente e imediata nos casos de denúncias feitas por <strong>Você</strong> relacionadas à conteúdos que violem os princípios da ética, moral e bons costumes, que sejam ilícitos e que envolvam, especialmente, menores de idade.</li>
                    </ol>
                  </li>
                  <li>
                    <strong>Atualização dos Termos.</strong> Estes Termos estão sujeitos a constante melhoria e aprimoramento. Assim, <strong>Nós</strong> nos reservamos ao direito de modificá-los a qualquer momento, conforme sua finalidade ou conveniência, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, incluindo de órgãos reguladores, ou seja, <strong>Você</strong> deverá verificá-los sempre que efetuar o acesso em Nossos Ambientes.
                    <ol type="1">
                      <li>Ocorrendo atualizações nestes Termos, <strong>Nós</strong> notificaremos <strong>Você</strong> pelas ferramentas disponíveis em Nossos Ambientes ou pelos meios de contato fornecidos por <strong>Você</strong>.</li>
                      <li>Ao navegar por Nossos Ambientes e utilizar suas funcionalidades, <strong>Você</strong> aceita guiar-se pelos Termos que se encontram vigentes na data de acesso. Por isso, é recomendável que <strong>Você</strong> se atualize das condições vigentes.</li>
                    </ol>
                  </li>
                  <li><strong>Novação e renúncia.</strong> A tolerância do eventual descumprimento de quaisquer condições destes Termos não constituirá renúncia ou novação nem nos impedirá de exigir estas condições a qualquer tempo.</li>
                  <li><strong>Nulidade.</strong> Se alguma disposição destes Termos for julgada inaplicável ou sem efeito, o restante continuará a viger normalmente.</li>
                  <li><strong>Comunicação.</strong> <strong>Você</strong> reconhece que toda comunicação realizada por <em>e-mail</em> ou telefone (ao endereço de <em>e-mail</em> e número de telefone/celular informados no seu cadastro) é válida, eficaz e suficiente para a divulgação de qualquer assunto que se refira aos Nossos Ambientes.</li>
                  <li><strong>Data e hora.</strong> Nossos Ambientes possuem como base a data e horário oficial de Brasília (UTC/GMT -03:00).</li>
                  <li><strong>Lei aplicável e foro.</strong> Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro da Comarca de Limeira, Estado de São Paulo, para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.</li>
                </ol>
              </li>
            </ol>
            <br />
          </section>
        }

        {
          (entityTypes.includes('student') || entityTypes.includes('noentity')) &&
          <section>
            {!this.props.noHeader && <h2 id="policy-student" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CLASSAPP PARA ALUNOS E RESPONSÁVEIS (“POLÍTICA”)</h2>}

            <p style={{ textAlign: 'right' }}>Atualizada em 19 de julho de 2021.</p>
            <ol type="1">
              <li><strong>INTRODUÇÃO</strong></li>
              <p>A presente Política tem por finalidade demonstrar o compromisso da</p>

              <div style={{ border: '1px solid black', padding: '1rem', marginBottom: '2rem' }}>
                <p><strong>CLASSAPP SISTEMAS LTDA.</strong>, (“<strong>Nós</strong>”)</p>
                <p>Rua Carlos Gomes, 1321, 8º andar, sala 801 a 808, Centro, Limeira – SP, CEP: 13.480-013</p>
                <p>CNPJ: 21.560.411/0001-01</p>
              </div>

              <p>com a privacidade e a proteção dos seus Dados, de forma clara e transparente, de acordo com as leis em vigor, em especial a Lei n.º 13.709/2018 (“Lei Geral de Proteção de Dados Pessoais”).</p>
              <p>Esta Política descreve as principais regras sobre o tratamento dos seus Dados, em especial Dados Pessoais e Dados Pessoais Sensíveis, quando <strong>Você</strong> faz uso dos nossos serviços e funcionalidades, seja por meio da área logada disponível em nosso site institucional &lt;www.classapp.com.br&gt; (“Portal ClassApp”) ou por meio do acesso ao nosso aplicativo (“Aplicativo ClassApp”), em conjunto denominados de “Nossos Ambientes”.</p>
              <p><strong>Nós</strong> divulgamos esta Política para lhe dar transparência às regras de privacidade aplicáveis, garantindo que o tratamento dos Dados Pessoais e Dados Pessoais Sensíveis servirá apenas para possibilitar a prestação de nossos serviços, observando-se todos os princípios previstos na legislação de proteção de dados aplicável. Caso tenha qualquer dúvida, estamos à disposição para ouvi-lo. Sua opinião é muito importante para <strong>Nós</strong>.</p>
              <p>Para acessar e utilizar das funcionalidades ofertadas em Nossos Ambientes, <u><strong>Você deve </strong>declarar que fez a leitura completa e atenta desta Política, estando plenamente ciente dos termos aqui estipulados e conferindo, assim, sua livre e expressa concordância com estes, incluindo a coleta dos Dados aqui mencionados, bem como seu tratamento para os fins abaixo especificados</u>.</p>
              <p>Estamos sempre buscando lhes oferecer serviços e funcionalidades com a maior eficiência possível, nos atualizando constantemente para isto. Por esse motivo, esta Política poderá sofrer ajustes a qualquer tempo, cabendo a <strong>Você</strong> verificá-la sempre que possível por meio do acesso aos Nossos Ambientes.</p>

              <div style={{ border: '1px solid black', padding: '1rem', marginBottom: '2rem' }}>
                <p style={{ textAlign: 'center', marginTop: 0 }}>   <strong>NOTA ESPECIAL PARA PAIS E REPRESENTANTES LEGAIS</strong></p>
                <p style={{ textAlign: 'center' }}><strong>Nós </strong>realizamos o Tratamento de Dados Pessoais, inclusive Dados Pessoais Sensíveis, de menores de 18 (dezoito) anos em Nossos Ambientes.</p>
                <p style={{ textAlign: 'center' }}>Ainda que haja a coleta de consentimento dos pais e responsáveis legais pelas Instituições de Ensino para o Tratamento dos Dados, estes deverão supervisionar as atividades <em>online</em> de seus filhos e/ou tutelados, responsabilizando-se pelo uso devido e regular de Nossos Ambientes.</p>
                <p style={{ textAlign: 'center' }}>As atividades de <u>adolescentes maiores de 16 anos e menores de 18 anos</u> devem ser assistidas pelos pais ou representantes legais.</p>
              </div>
              <br />

              <li>
                <strong>DEFINIÇÕES</strong>
                <ol type="1">
                  <li>Sem prejuízo de outras definições constantes nesta Política, as palavras e expressões abaixo indicadas, sempre que utilizadas pela primeira letra maiúscula, terão as seguintes definições:</li>
                  <ol type="i">
                    <li><strong>Anonimização:</strong> Utilização de meios técnicos razoáveis e disponíveis no momento do Tratamento, por meio dos quais um Dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.</li>
                    <li><strong>Aplicativo ClassApp:</strong> Aplicativo disponibilizado aos alunos, pais, responsáveis legais, pedagógicos e financeiros, com a finalidade de proporcionar uma alternativa conveniente para que estes interajam com nossos conteúdos e utilizem as funcionalidades e serviços oferecidos, podendo ser baixado por meio do <em>App Store</em> e <em>Google Play</em>.</li>
                    <li><strong><em>Cloud Computing</em>:</strong> Ou computação em nuvem, é a tecnologia de virtualização de serviços construída a partir da interligação de mais de um servidor por meio de uma rede de informação comum (p.ex. a <em>Internet</em>), com objetivo de reduzir custos e aumentar a disponibilidade dos serviços sustentados.</li>
                    <li><strong>Conta de Acesso:</strong> Credencial de um Usuário que permite acesso à área restrita e às funcionalidades exclusivas existentes no Portal ClassApp e no Aplicativo ClassApp, definida pela combinação de <em>e-mail</em> ou número de celular e senha.</li>
                    <li><strong><em>Cookies</em>:</strong> Pequenos arquivos digitais em formato de texto que são armazenados no seu dispositivo (computador, <em>smartphone</em>, <em>tablet</em>, etc.) pelo navegador de <em>internet</em> e que guardam informações relacionadas às suas preferências, como idioma preferido, localização, recorrência das suas sessões, e outras variáveis que os desenvolvedores consideram relevantes para tornar a sua experiência muito mais eficiente.</li>
                    <li><strong>Dados:</strong> Quaisquer informações inseridas, tratadas ou transmitidas através dos Nossos Ambientes.</li>
                    <li><strong>Dados Pessoais:</strong> Dados relacionados a pessoa natural identificada ou identificável.</li>
                    <li><strong>Dados Pessoais Sensíveis:</strong> aqueles de origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</li>
                    <li><strong>Decisões unicamente automatizadas:</strong> Trata-se de decisões que afetam um usuário que foram programadas para funcionar automaticamente, sem a necessidade de uma operação humana, com base em Tratamento automatizado de Dados Pessoais.</li>
                    <li><strong>Encarregado (<em>Data Protection Officer -DPO</em>):</strong> Pessoa indicada por <strong>Nós</strong> para atuar como canal de comunicação entre <strong>Nós</strong>, os titulares dos Dados Pessoais e a Autoridade Nacional de Proteção de Dados (ANPD).</li>
                    <li><strong><em>ID </em>de Sessão:</strong> Identificação da sessão de Usuários quando é efetuado o acesso em Nossos Ambientes.</li>
                    <li><strong>Instituições de Ensino:</strong> Instituições de ensino clientes, que contratam nossos serviços para utilização por seus funcionários, representantes, terceiros, alunos e responsáveis por alunos.</li>
                    <li><strong><em>IP</em>:</strong> Abreviatura de <em>Internet Protocol</em>. É conjunto alfanumérico que identifica os dispositivos dos usuários na <em>Internet</em>.</li>
                    <li><strong><em>Link</em>:</strong> Terminologia para endereço de <em>internet.</em></li>
                    <li><strong><em>Log</em>:</strong> Registro de atividades realizadas pelos Usuários em Nossos Ambientes.</li>
                    <li><strong><em>Login</em>:</strong> Processo para acessar um sistema informático restrito feito através da autenticação ou identificação do Usuário, usando credenciais previamente cadastradas no sistema.</li>
                    <li><strong>Portal ClassApp:</strong> Designa a área logada constante em nosso site institucional, disponível através do endereço eletrônico &lt;www.classapp.com.br&gt;.</li>
                    <li><strong>Tratamento:</strong> Toda operação realizada com Dados Pessoais e Dados Pessoais Sensíveis, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</li>
                    <li><strong>Usuário(s) ou Você:</strong> Pessoas físicas que utilizam Nossos Ambientes através de perfis de acessos como “alunos” ou “responsáveis”. São os alunos, pais, representantes legais, pedagógicos e financeiros que desejam experimentar nossos serviços através de uma simulação ou que foram previamente cadastrados em nosso sistema pelas Instituições de Ensino para utilização de nossos serviços e funcionalidades disponíveis.</li>
                  </ol>
                </ol>
              </li>

              <li>
                <strong>SOBRE DADOS QUE COLETAMOS</strong>
                <ol type="1">
                  <li><strong>Como coletamos Dados.</strong> Coletamos os Dados compartilhados por <strong>Você</strong> com as Instituições de Ensino para criação de sua Conta de Acesso de Usuário. Além disso, poderemos coletar Dados Pessoais e Dados Pessoais Sensíveis quando <strong>Você</strong> os submete ou interage em Nossos Ambientes e serviços, o que inclui:</li>

                  <div style={{ border: '1px solid black', padding: '0', marginTop: '1rem', marginBottom: '2rem', marginLeft: '-5rem' }}>
                    <ol type="i" style={{ padding: '0' }}>
                      <div>
                        <div>
                          <p><strong>O que coletamos?</strong></p>
                        </div>
                        <div>
                          <p><strong>Para que coletamos?</strong></p>
                        </div>
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <p><strong>Dados cadastrais</strong></p>
                      </div>
                      <div>
                        <div>
                          <div>
                            <p><strong>ALUNO:</strong></p>
                            <p>Nome completo, turma, <em>e-mail</em>, celular e foto de perfil (opcional)</p>
                          </div>
                          <div>
                            <p><strong>RESPONSÁVEL<sup>1</sup>:</strong></p>
                            <p>Nome completo, <em>e-mail</em>, celular, CPF e RG</p>
                            <sub>1 - Responsável legal, pedagógico e financeiro do aluno.</sub>
                          </div>
                        </div>
                        <div>
                          <li>Identificar e autenticar <strong>Você</strong>;</li>
                          <li>Cumprir as obrigações decorrentes do uso dos nossos serviços e funcionalidades, inclusive para atendimento de disposições legais e regulatórias;</li>
                          <li>Possibilitar a criação de uma Conta de Acesso para utilização de nossos serviços e funcionalidades, inclusive para realização de simulações em Nossos Ambientes;</li>
                          <li>Atender adequadamente às suas solicitações e dúvidas;</li>
                          <li>Aperfeiçoar o uso e a experiência interativa durante sua navegação em Nossos Ambientes;</li>
                          <li>Promover nossos serviços, além de lhe informar sobre novidades, funcionalidades, conteúdos, notícias e demais informações relevantes para <strong>Você</strong>;</li>
                          <li>Garantir a validade jurídica de assinaturas eletrônicas em contratos firmados com as Instituições de Ensino e proporcionar o processamento de operações financeiras solicitadas por <strong>Você</strong>;</li>
                          <li>Garantir a portabilidade dos Dados cadastrais para outro Controlador do mesmo ramo de nossa atuação, caso solicitado por <strong>Você</strong>, cumprindo com obrigação do artigo 18 da Lei Geral de Proteção de Dados Pessoais;</li>
                          <li>Proteger <strong>Você</strong> no que diz respeito à prevenção de fraudes e riscos associados, além do cumprimento de obrigações legais e regulatórias;</li>
                        </div>
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <p><strong>Dados de mídias</strong></p>
                      </div>
                      <div>
                        <div>
                          <div>
                            <p>Áudios, fotos e vídeos</p>
                          </div>
                        </div>
                        <div>
                          <li>Possibilita a utilização por <strong><strong>Você</strong> </strong>da funcionalidade relativa ao compartilhamento de mídias em Nossos Ambientes;</li>
                        </div>
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <p><strong>Dados de pagamento</strong></p>
                      </div>
                      <div>
                        <div>
                          <div>
                            <p>Número do cartão de crédito e informações correlatas</p>
                          </div>
                        </div>
                        <div>
                          <li>Cumprir com as obrigações legais e as decorrentes de nossos serviços e funcionalidades de pagamentos disponíveis em Nossos Ambientes, compartilhando os Dados com a empresa terceira responsável em processar o pagamento;</li>
                          <li>Proteção do crédito e procedimentos de cobrança;</li>
                        </div>
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <p><strong>Dados de saúde (opcionais)</strong></p>
                      </div>
                      <div>
                        <div>
                          <div>
                            <p>Suspeita de casos de COVID-19 no ambiente familiar, contato com pessoas infectadas e confirmação de contágio</p>
                          </div>
                        </div>
                        <div>
                          <li>Permitir a utilização de nossos serviços opcionais relacionados ao monitoramento de saúde e bem-estar dos alunos, os quais visam evitar a disseminação de doenças contagiosas na comunidade escolar;</li>
                          <li>Responder às pesquisas semanais de controle e acompanhamento enviadas pelas Instituições de Ensino;</li>
                        </div>
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <p><strong>Dados de identificação digital</strong></p>
                      </div>
                      <div>
                        <div>
                          <div>
                            <p><em>ID</em> da Sessão</p>
                          </div>
                          <div>
                            <p><em>Log</em> de <em>IP</em></p>
                          </div>
                          <div>
                            <p>Endereço <em>IP</em> e Porta Lógica de Origem</p>
                          </div>
                          <div>
                            <p>Informações de dispositivo (tipo do dispositivo, conexões de rede, nome do dispositivo, versão do sistema operacional e conexão de <em>internet</em> utilizada)</p>
                          </div>
                          <div>
                            <p>Geolocalização</p>
                          </div>
                          <div>
                            <p>Registros de data e horário de cada ação que <strong>Você</strong> realizar</p>
                          </div>
                          <div>
                            <p>Quais telas <strong>Você</strong> acessou</p>
                          </div>
                          <div>
                            <p><em>Cookies</em></p>
                          </div>
                        </div>
                        <div>
                          <li>Identificar e autenticar <strong>Você</strong>;</li>
                          <li>Efetuar estatísticas, estudos, pesquisas e levantamentos relacionados ao comportamento do Usuário em Nossos Ambientes, realizando tais operações de forma anonimizada;</li>
                          <li>Garantir a validade jurídica das informações fornecidas por <strong>Você</strong> quando do preenchimento de enquetes e formulários criados pelas Instituições de Ensino e disponibilizados em Nossos Ambientes;</li>
                          <li>Corrigir problemas de tecnologia;</li>
                          <li>Proteger <strong>Você</strong> no que diz respeito à prevenção de fraudes e riscos associados, além do cumprimento de obrigações legais e regulatórias; e</li>
                          <li>Cumprir com obrigações legais de manutenção de registros estabelecidas pelo Marco Civil da Internet - Lei 12.965/2014.</li>
                        </div>
                      </div>
                    </ol>
                  </div>

                  <li>Para finalidade de prevenção a fraudes e garantia da autenticidade dos Dados fornecidos, <strong>Nós</strong> poderemos solicitar outros Dados Pessoais adicionais, não previstos na tabela acima, para confirmação das informações fornecidas pelos Usuários. Neste caso, <strong>Nós</strong> poderemos entrar em contato diretamente com <strong>Você</strong> para efetuar a requisição.</li>
                  <li><strong>Dados necessários.</strong> A prestação dos nossos serviços e funcionalidades disponíveis em Nossos Ambientes dependem diretamente de determinados Dados informados na tabela acima, em especial, dos Dados cadastrais para criação de sua Conta de Acesso. Caso <strong>Você</strong> opte por não fornecer alguns desses Dados, poderemos ficar impossibilitados de executar parte de nossos serviços e funcionalidades em Nossos Ambientes.</li>
                  <li>
                    <strong>Atualização e veracidade dos dados.</strong> <strong>Você</strong> é o único responsável pela precisão, veracidade ou falta dela em relação aos Dados que <strong>Você</strong> fornece ou pela sua desatualização. Fique atento pois é de sua responsabilidade garantir a exatidão ou mantê-los atualizados.
                    <ol type="1">
                      <li>Da mesma forma, <strong>Nós</strong> não somos obrigados a processar ou tratar quaisquer dos seus Dados se houver razões para crer que tal processamento ou Tratamento possa nos imputar infração da legislação aplicável, ou se <strong>Você</strong> estiver utilizando Nossos Ambientes para quaisquer fins ilegais, ilícitos ou contrários à moralidade.</li>
                    </ol>
                  </li>
                  <li><strong>Base de Dados.</strong> A base de Dados, de propriedade das Instituições de Ensino, formada por meio da coleta de Dados, estará sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos dos negócios descritos nesta Política.</li>
                  <li>
                    <strong>Tecnologias empregadas.</strong> <strong>Nós</strong> utilizamos a(s) seguinte(s) tecnologia(s):
                    <ol type="1">
                      <ol type="i">
                        <li><em>Cookies</em>, utilizados para melhorar a funcionalidade do Portal ClassApp. A qualquer tempo, <strong>Você</strong> pode bloquear o uso de <em>Cookies</em> através das configurações o seu navegador de <em>Internet</em>, hipótese em que algumas funcionalidades do Portal ClassApp poderão ser limitadas;</li>
                        <li><em>Tags </em>de <em>Pixel</em>, possibilitam a coleta de informações (arquivos gravados em seu computador) referentes ao seu histórico de navegação, facilitam seu <em>login</em> (mediante armazenamento de suas credenciais) e promovem a interação entre <strong>Você</strong> e os serviços oferecidos por <strong>Nós</strong>. As <em>Tags</em> de <em>Pixel</em> também poderão ser desabilitadas mediante configurações das opções existentes em seu navegador de <em>Internet</em>;</li>
                        <li><em>Log files</em>, registram atividades referentes ao uso de nosso Portal ClassApp, tais como acesso a outros <em>websites</em>, o tipo e a qualidade de conexão de <em>internet</em>, <em>hardwares</em> ou <em>softwares</em> utilizados e as funcionalidades de determinado serviço que tenha sido utilizado por <strong>Você</strong>. Os <em>log files</em> poderão ser utilizados para monitorar o tráfego no Portal ClassApp e solucionar problemas técnicos;</li>
                        <li><em>Web beacons</em>, usados para rastrear anonimamente os seus padrões de uso <em>online</em>, a exemplo de <em>e-mails</em> abertos e <em>links</em> clicados por <strong>Você</strong>. As informações coletadas poderão identificar <em>websites</em>, serviços, produtos e funcionalidades populares, as quais serão utilizadas para personalizar nossos serviços para <strong>Você</strong>;</li>
                        <li><em>Google Analytics</em>, para registro de dados de navegação não identificáveis para análise; e</li>
                        <li><em>Adwords</em> e <em>Facebook</em>, para registro de eventos durante a navegação.</li>
                      </ol>
                      <li><strong>Nós</strong> também poderemos utilizar ferramentas fornecidas por empresas terceiras, as quais registrarão as atividades de interação do Usuário com Nossos Ambientes. O Tratamento destes Dados será realizado de forma anônima, com a finalidade de melhorar a experiência e conteúdo de Nossos Ambientes, observando-se o disposto na cláusula 5.7. abaixo.</li>
                      <li>Todas as tecnologias utilizadas respeitarão sempre a legislação vigente e os termos desta Política.</li>
                    </ol>
                  </li>
                  <li style={{ border: '1px solid black', padding: '1rem 1rem 1rem 3.9rem', marginLeft: '-3.9rem' }}><strong>Nós</strong> não utilizamos nenhum tipo de decisão unicamente automatizada que impacte <strong>Você</strong>.</li>
                </ol>
              </li>

              <li>
                <strong>COMO COMPARTILHAMOS DADOS E INFORMAÇÕES</strong>
                <ol type="1">
                  <li>
                    <strong>Hipóteses de compartilhamento dos Dados.</strong> Os Dados coletados e as atividades registradas podem ser compartilhados, sempre respeitando o envio do mínimo de informações necessárias para atingir as finalidades:
                    <ol type="i">
                      <li>Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determinação legal, requerimento, requisição ou ordem judicial;</li>
                      <li>Com empresas parceiras e prestadores de serviços necessários à execução dos nossos serviços e funcionalidades, sempre exigindo de tais organizações o cumprimento das diretrizes de segurança e proteção de dados, conforme item 5.7. desta Política; e</li>
                      <li>De forma automática, em caso de movimentações societárias, como fusão, aquisição e incorporação.</li>
                    </ol>
                  </li>
                  <li><strong>Anonimização de Dados.</strong> Para as finalidades de pesquisas de inteligência de mercado, divulgação de dados à imprensa, realização de propagandas e outras atividades aplicáveis, os dados fornecidos por <strong>Você</strong> serão compartilhados de forma anonimizada, isto é, de forma que não possibilite a sua identificação.</li>
                </ol>
              </li>

              <li>
                <strong>COMO PROTEGEMOS SEUS DADOS E COMO VOCÊ TAMBÉM PODERÁ PROTEGÊ-LOS</strong>
                <ol type="1">
                  <li>
                    <strong>Medidas que tomamos.</strong> <strong>Nós</strong> empregamos os melhores esforços para manter a privacidade e a segurança das informações por meio da adoção de medidas de segurança técnicas, físicas e administrativas:
                    <ol type="i">
                      <li><strong>medidas técnicas</strong>, como por exemplo transmissão de Dados Pessoais por meio de página de <em>internet </em>segura, armazenamento de Dados em meios eletrônicos que mantêm altos padrões de segurança, utilização de sistema cujo acesso é controlado;</li>
                      <li><strong>medidas físicas</strong>, tais como acesso restrito a pessoas autorizadas mantido em instalações que incluem, uso de ferramentas de segurança de mercado; e</li>
                      <li><strong>medidas administrativas</strong>, incluindo a adoção de Políticas e Normas de Segurança, treinamentos/conscientização de colaboradores, acordos de confidencialidade.</li>
                    </ol>
                  </li>
                  <li><strong>Compartilhamento de senhas.</strong> <strong>Você</strong> também é responsável pelo sigilo de seus Dados Pessoais e deve ter sempre ciência de que o compartilhamento de senhas e dados de acesso viola esta Política e pode comprometer a segurança dos seus Dados e dos Nossos Ambientes.</li>
                  <li><strong>Cuidados que <strong>Você</strong> dever tomar.</strong> É muito importante que <strong>Você</strong> proteja seus Dados contra acesso não autorizado ao seu dispositivo, conta ou senha. Também é muito importante que <strong>Você</strong> saiba que <strong>Nós</strong> nunca enviaremos mensagens eletrônicas com anexos que possam ser executados (extensões: .exe, .com, entre outros) ou ainda <em>links</em> para eventuais <em>downloads</em>. Nossos contatos têm a finalidade de trazer informações sobre suas dúvidas e solicitações, bem como demais informações relevantes para <strong>Você</strong>.</li>
                  <li><strong>Acesso aos Dados Pessoais, proporcionalidade e relevância.</strong> Internamente, os Dados Pessoais coletados são acessados somente por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade, necessidade e relevância para os objetivos do nosso negócio, além do compromisso de confidencialidade e preservação da sua privacidade disposto na presente Política. Profissionais e/ou pessoas autorizadas que se utilizarem indevidamente desses Dados, violando os termos desta Política, estarão sujeitos às penalidades previstas em nosso processo disciplinar, sem exclusão das demais penalidades legais aplicáveis.</li>
                  <li><strong>Segurança da informação.</strong> Todas as transações de pagamento, com cartão de crédito ou não, são executadas com a tecnologia SSL (<em>secure socket layer</em>), garantindo que todos os seus Dados não sejam ilicitamente divulgados. Além disso, essa tecnologia visa impedir que as informações sejam transmitidas ou acessadas por terceiros.</li>
                  <li>
                    <strong><em>Links </em>externos.</strong> Quando <strong>Você</strong> utilizar Nossos Ambientes, <strong>Você</strong> poderá ser conduzido, via <em>link</em> a outros portais ou plataformas, inclusive ambientes pertencentes a terceiros prestadores de serviços das Instituições de Ensino, como resultado da integração de Nossos Ambientes com ambientes de terceiros requisitada pelas Instituições de Ensino, podendo estes coletar suas informações e ter sua própria Política de Tratamento de Dados.
                    <ol type="1">
                      <li>Caberá a <strong>Você</strong> ler a Políticas de Privacidade e Tratamento de Dados de tais portais ou plataformas fora de Nossos Ambientes, sendo de sua responsabilidade aceitá-la ou rejeitá-la. <strong>Nós</strong> não somos responsáveis pelas Políticas de Privacidade e Tratamento de Dados de terceiros e nem pelo conteúdo de quaisquer <em>websites,</em> conteúdo dos serviços ligados à ambientes que não os nossos.</li>
                      <li><strong>Serviços de parceiros.</strong> <strong>Nós</strong> possuímos parceiros comerciais que, eventualmente, podem oferecer serviços por meio de funcionalidades ou <em>websites</em> que podem ser acessados a partir de Nossos Ambientes. Os Dados fornecidos por <strong>Você</strong> a estes parceiros serão de responsabilidade destes, estando assim sujeitos às suas próprias práticas de coleta e uso de dados.</li>
                    </ol>
                  </li>
                  <li><strong>Processamento por terceiros sob nossa diretriz.</strong> Caso empresas terceirizadas realizem o Tratamento em nosso nome de quaisquer Dados Pessoais que coletamos, as mesmas respeitarão as condições aqui estipuladas e as normas de segurança da informação, obrigatoriamente.</li>
                  <li>
                    <strong>Comunicação por <em>e-mail</em>.</strong> Para otimizar e melhorar nossa comunicação, quando enviamos um <em>e-mail</em> para <strong>Você</strong> podemos receber uma notificação quando eles são abertos, desde que esta possibilidade esteja disponível. <strong>Nós</strong> não utilizamos serviços de terceiros para enviar <em>e-mails</em> em nosso nome. É importante <strong>Você</strong> ficar atento, pois os e-mails são enviados somente pelo domínio: @classapp.com.br; @clsp.co; ou @classapp.co.
                    <ol type="1">
                      <li>Caso <strong>Você</strong> receba um <em>e-mail</em> que acredita não ter sido enviado por <strong>Nós</strong>, <strong>Você</strong> deverá se abster de adotar qualquer ação e deverá entrar em contato conosco imediatamente por meio de nossos Canais de Atendimento, a fim de confirmar sua veracidade.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>COMO ARMAZENAMOS SEUS DADOS PESSOAIS E O REGISTRO DE ATIVIDADES</strong>
                <ol type="1">
                  <li>Os Dados Pessoais coletados e os registros de atividades são armazenados em ambiente seguro e controlado por um prazo mínimo que segue a tabela abaixo:</li>

                  <div className="simple-table">
                    <div>
                      <div>
                        <p style={{ textAlign: 'center' }}><strong>PRAZO DE ARMAZENAMENTO</strong></p>
                      </div>
                      <div>
                        <p style={{ textAlign: 'center' }}><strong>FUNDAMENTO LEGAL</strong></p>
                      </div>
                    </div>

                    <div>
                      <div>
                        <p style={{ textAlign: 'center' }}>Enquanto durar a relação e não houver pedido de apagamento ou revogação de consentimento</p>
                      </div>
                      <div>
                        <p style={{ textAlign: 'center' }}>Art. 9º, inciso II da Lei Geral de Proteção de Dados Pessoais</p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p style={{ textAlign: 'center' }}>5 anos após o término da relação</p>
                      </div>
                      <div>
                        <p style={{ textAlign: 'center' }}>Arts. 12 e 34 do Código de Defesa do Consumidor</p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p style={{ textAlign: 'center' }}>6 meses para os Dados de Identificação Digital</p>
                      </div>
                      <div>
                        <p style={{ textAlign: 'center' }}>Art. 15 do Marco Civil da <em>Internet</em></p>
                      </div>
                    </div>
                  </div>

                  <li><strong>Prazos de armazenamento superiores.</strong> Para fins de auditoria, segurança, controle de fraudes e preservação de direitos poderemos permanecer com o histórico de registro de seus Dados por prazo maior nas hipóteses que a lei ou norma regulatória, assim estabelecer ou para preservação de direitos.</li>
                  <li style={{ border: '1px solid black', padding: '1rem 1rem 1rem 3.9rem', marginLeft: '-3.9rem' }}>Os Dados coletados serão armazenados em nossos servidores localizados no Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem (<em>cloud computing</em>), <strong>o que poderá exigir uma transferência e/ou processamento destes Dados fora do Brasil.</strong></li>
                </ol>
              </li>

              <li>
                <strong>QUAIS SÃO OS SEUS DIREITOS E COMO EXERCÊ-LOS</strong>
                <ol type="1">
                  <li><strong>Seus direitos básicos.</strong> <strong>Você</strong> poderá solicitar a confirmação da existência de Tratamento de Dados Pessoais, além da exibição ou retificação de seus Dados Pessoais, por meio dos Canais de Atendimento, nos termos do item “8.4”.</li>
                  <li>
                    <strong>Limitação, oposição e exclusão de dados.</strong> Pelos Canais de Atendimento, <strong>Você</strong> poderá também requerer:
                    <ol type="i">
                      <li>A limitação do uso de seus Dados Pessoais;</li>
                      <li>Manifestar sua oposição e/ou revogar o consentimento quanto ao uso de seus Dados Pessoais; ou</li>
                      <li>Solicitar a exclusão de seus Dados Pessoais que tenham sidos coletados por <strong>Nós</strong>.</li>
                    </ol>
                    <ol type="1">
                      <li>Caso <strong>Você</strong> solicite a exclusão de seus Dados Pessoais, pode ocorrer que os Dados precisem ser mantidos por período superior ao pedido de exclusão, nos termos do artigo 16 da Lei Geral de Proteção de Dados Pessoais, para (i) cumprimento de obrigação legal ou regulatória, (ii) estudo por órgão de pesquisa, e (iii) transferência a terceiro (respeitados os requisitos de Tratamento de dados dispostos na mesma Lei). Em todos os casos mediante a anonimização dos Dados Pessoais, desde que possível.</li>
                      <li>Findos o prazo de manutenção e a necessidade legal, os Dados Pessoais serão excluídos com uso de métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>INFORMAÇÕES SOBRE ESSA POLÍTICA</strong>
                <ol type="1">
                  <li>
                    <strong>Alteração do teor e atualização.</strong> <strong>Você</strong> reconhece o nosso direito de alterar o teor desta Política a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo a <strong>Você</strong> verificá-la sempre que efetuar o acesso aos Nossos Ambientes ou utilizar nossos serviços e funcionalidades.
                    <ol type="1">
                      <li>Ocorrendo atualizações neste documento e que demandem nova coleta de consentimento, <strong>Você</strong> poderá ser notificado por meio dos canais de contatos que <strong>Você</strong> informar ou através de seu acesso em Nossos Ambientes.</li>
                      <li>Caso <strong>Você</strong> não concorde com referidas alterações, <strong>Você</strong> poderá nos solicitar o cancelamento de sua Conta de Acesso, ficando ciente das implicações de sua ação, em especial, da impossibilidade de utilização de nossos serviços e funcionalidades disponíveis em Nossos Ambientes.</li>
                    </ol>
                  </li>
                  <li><strong>Inaplicabilidade.</strong> Caso algum ponto desta Política seja considerado inaplicável por Autoridade de Dados ou judicial, as demais condições permanecerão em pleno vigor e efeito.</li>
                  <li><strong>Comunicação eletrônica.</strong> <strong>Você</strong> reconhece que toda comunicação realizada por <em>e-mail</em> (aos endereços informados no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra forma digital, também são válidas, eficazes e suficiente para a divulgação de qualquer assunto que se refira às funcionalidades que oferecemos em Nossos Ambientes, aos seus Dados, bem como qualquer outro assunto abordado, sendo exceção apenas o que essa Política prever como tal.</li>
                  <li>
                    <strong>Canais de Atendimento.</strong> Em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade e Tratamento de Dados, <strong>Você</strong> poderá entrar em contato por meio dos Canais de Atendimento apontados a seguir:
                    <ol type="i">
                      <li>Canais para dúvidas:
                        <p><strong>Chat <em>Online</em>:</strong> ferramenta disponível no Portal ClassApp para envio de mensagens instantâneas, cujo horário de funcionamento é de segunda-feira a sexta-feira, das 09 horas às 17 horas, exceto em feriados adotados por <strong>Nós</strong>;</p>
                        <p><strong>Envie uma Solicitação:</strong> através da aba “Ajuda” disponível em Nossos Ambientes, será possível nos enviar uma mensagem contendo sua solicitação; e</p>
                        <p><strong>Envio de correspondência física:</strong> Rua Carlos Gomes, 1321, 8º andar, sala 801 a 808, Centro, Limeira – SP, CEP: 13.480-013.</p>
                      </li>
                      <li>Canal para contato com o Encarregado de Dados: <a href="mailto:privacidade@classapp.com.br" rel="noopener noreferrer" target="_blank">privacidade@classapp.com.br</a></li>
                    </ol>
                  </li>
                  <li>
                    <strong>Lei aplicável e foro.</strong> Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro do seu domicílio para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
                    <ol type="1">
                      <li><strong>Você</strong>, caso não possua domicílio no Brasil, e em razão dos serviços oferecidos por <strong>Nós</strong> ser apenas em território nacional, se submete à legislação brasileira, concordando, portanto, que em havendo litígio a ser solucionado, a ação deverá ser proposta no Foro da Comarca de Limeira, Estado de São Paulo.</li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
            <br />
          </section>
        }

        {
          (entityTypes.includes('staff') || entityTypes.includes('admin')) &&
          <section>
            {!this.props.noHeader && <h2 id="policy-staff-admin" className="avoid-header-overlapping" style={{ marginBottom: '38px' }}>POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CLASSAPP PARA ADMINISTRADOR DE SISTEMA E FUNCIONÁRIO DA INSTITUIÇÃO DE ENSINO (“POLÍTICA”)</h2>}

            <p style={{ textAlign: 'right' }}>Atualizada em 19 de julho de 2021.</p>
            <ol type="1">
              <li>
                <strong>INTRODUÇÃO</strong>
                <ol type="1">
                  <p>A presente Política tem por finalidade demonstrar o compromisso da</p>

                  <div style={{ border: '1px solid black', padding: '1rem', marginBottom: '2rem' }}>
                    <p><strong>CLASSAPP SISTEMAS LTDA.</strong>, (“<strong>Nós</strong>”)</p>
                    <p>Rua Carlos Gomes, 1321, 8º andar, sala 801 a 808, Centro, Limeira – SP, CEP: 13.480-013</p>
                    <p>CNPJ: 21.560.411/0001-01</p>
                  </div>

                  <p>com a privacidade e a proteção dos seus Dados, de forma clara e transparente, de acordo com as leis em vigor, em especial a Lei n.º 13.709/2018 (“Lei Geral de Proteção de Dados Pessoais”).</p>
                  <p>Esta Política descreve as principais regras sobre o tratamento dos seus Dados, em especial Dados Pessoais, quando <strong>Você</strong> faz uso dos nossos serviços e funcionalidades, seja por meio da área logada disponível em nosso site institucional &lt;www.classapp.com.br&gt; (“Portal ClassApp”) ou por meio do acesso ao nosso aplicativo (“Aplicativo ClassApp”), em conjunto denominados de “Nossos Ambientes”.</p>
                  <p><strong>Nós</strong> divulgamos esta Política para lhe dar transparência às regras de privacidade aplicáveis, garantindo que o tratamento dos Dados Pessoais servirá apenas para possibilitar a prestação de nossos serviços, observando-se todos os princípios previstos na legislação de proteção de dados aplicável. Caso tenha qualquer dúvida, estamos à disposição para ouvi-lo. Sua opinião é muito importante para <strong>Nós</strong>.</p>
                  <p>Para acessar e utilizar das funcionalidades ofertadas em Nossos Ambientes, <u><strong>Você deve </strong>declarar que fez a leitura completa e atenta desta Política, estando plenamente ciente dos termos aqui estipulados e conferindo, assim, sua livre e expressa concordância com estes, incluindo a coleta dos Dados aqui mencionados, bem como seu tratamento para os fins abaixo especificados</u>.</p>
                  <p>Estamos sempre buscando lhes oferecer serviços e funcionalidades com a maior eficiência possível, nos atualizando constantemente para isto. Por esse motivo, esta Política poderá sofrer ajustes a qualquer tempo, cabendo a <strong>Você</strong> verificá-la sempre que possível por meio do acesso aos Nossos Ambientes.</p>
                </ol>
              </li>

              <li>
                <strong>DEFINIÇÕES</strong>
                <ol type="1">
                  <li>
                    Sem prejuízo de outras definições constantes nesta Política, as palavras e expressões abaixo indicadas, sempre que utilizadas pela primeira letra maiúscula, terão as seguintes definições:
                    <ol type="i">
                      <li><strong>Anonimização:</strong> Utilização de meios técnicos razoáveis e disponíveis no momento do Tratamento, por meio dos quais um Dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.</li>
                      <li><strong>Aplicativo ClassApp:</strong> Aplicativo disponibilizado às Instituições de Ensino com a finalidade de proporcionar uma alternativa conveniente para que estas interajam com nossos conteúdos e utilizem as funcionalidades e serviços oferecidos, podendo ser baixado por meio do <em>App Store</em> e <em>Google Play</em>.</li>
                      <li><strong><em>Cloud Computing</em>:</strong> Ou computação em nuvem, é a tecnologia de virtualização de serviços construída a partir da interligação de mais de um servidor por meio de uma rede de informação comum (p.ex. a <em>Internet</em>), com objetivo de reduzir custos e aumentar a disponibilidade dos serviços sustentados.</li>
                      <li><strong>Conta de Acesso:</strong> Credencial de um Usuário que permite acesso à área restrita e às funcionalidades exclusivas existentes no Portal ClassApp e no Aplicativo ClassApp, definida pela combinação de <em>e-mail</em> ou número de celular e senha.</li>
                      <li><strong><em>Cookies</em>:</strong> Pequenos arquivos digitais em formato de texto que são armazenados no seu dispositivo (computador, <em>smartphone</em>, <em>tablet</em>, etc.) pelo navegador de <em>internet</em> e que guardam informações relacionadas às suas preferências, como idioma preferido, localização, recorrência das suas sessões, e outras variáveis que os desenvolvedores consideram relevantes para tornar a sua experiência muito mais eficiente.</li>
                      <li><strong>Dados:</strong> Quaisquer informações inseridas, tratadas ou transmitidas através dos Nossos Ambientes.</li>
                      <li><strong>Dados Pessoais:</strong> Dados relacionados à pessoa natural identificada ou identificável.</li>
                      <li><strong>Decisões unicamente automatizadas:</strong> Trata-se de decisões que afetam um usuário que foram programadas para funcionar automaticamente, sem a necessidade de uma operação humana, com base em Tratamento automatizado de Dados Pessoais.</li>
                      <li><strong>Encarregado (<em>Data Protection Officer -DPO</em>):</strong> Pessoa indicada por <strong>Nós</strong> para atuar como canal de comunicação entre <strong>Nós</strong>, os titulares dos Dados Pessoais e a Autoridade Nacional de Proteção de Dados (ANPD).</li>
                      <li><strong><em>ID </em>de Sessão:</strong> Identificação da sessão de Usuários quando é efetuado o acesso em Nossos Ambientes.</li>
                      <li><strong>Instituições de Ensino:</strong> Instituições de ensino clientes, que contratam nossos serviços para utilização por seus funcionários, representantes, terceiros, alunos e responsáveis por alunos.</li>
                      <li><strong><em>IP</em>:</strong> Abreviatura de <em>Internet Protocol</em>. É um conjunto alfanumérico que identifica os dispositivos dos usuários na <em>Internet</em>.</li>
                      <li><strong><em>Link</em>:</strong> Terminologia para endereço de <em>internet.</em></li>
                      <li><strong><em>Login</em>:</strong> Processo para acessar um sistema informático restrito feito através da autenticação ou identificação do Usuário, usando credenciais previamente cadastradas no sistema.</li>
                      <li><strong>Portal ClassApp:</strong> Designa a área logada constante em nosso site institucional, disponível através do endereço eletrônico &lt;www.classapp.com.br&gt;.</li>
                      <li><strong>Termo:</strong> Contrato eletrônico, incluindo-se termos e condições gerais, que regulam a prestação dos serviços pela <strong>ClassApp</strong> às Instituições de Ensino.</li>
                      <li><strong>Tratamento:</strong> Toda operação realizada com Dados Pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</li>
                      <li><strong>Usuário(s) ou Você:</strong> Pessoas físicas, autorizadas pelas Instituições de Ensino a utilizar Nossos Ambientes, através dos perfis de acessos como “funcionários” ou “administradores/gerenciadores de sistema”.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>SOBRE DADOS QUE COLETAMOS</strong>
                <ol type="1">
                  <li><strong>Como coletamos Dados.</strong> <strong>Nós</strong> coletamos Dados Pessoais mínimos, isto é, apenas aqueles necessários para criação da Conta de Acesso do Usuário. Também, poderemos coletar Dados Pessoais quando <strong>Você</strong> interage em Nossos Ambientes e serviços, o que inclui:</li>

                  <div style={{ border: '1px solid black', padding: '0', marginTop: '1rem', marginBottom: '2rem', marginLeft: '-5rem' }}>
                    <ol type="i" style={{ padding: '0' }}>
                      <div>
                        <div>
                          <p><strong>O que coletamos?</strong></p>
                        </div>
                        <div>
                          <p><strong>Para que coletamos?</strong></p>
                        </div>
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <p><strong>Dados cadastrais</strong></p>
                      </div>
                      <div>
                        <div>
                          <div>
                            <p>Nome completo</p>
                          </div>
                          <div>
                            <p>E-mail</p>
                          </div>
                          <div>
                            <p>Telefone celular</p>
                          </div>
                          <div>
                            <p>Função exercida na Instituição de Ensino</p>
                          </div>
                          <div>
                            <p>Foto de perfil (opcional)</p>
                          </div>
                        </div>
                        <div>
                          <li>Identificar e autenticar <strong>Você</strong>;</li>
                          <li>Cumprir as obrigações contratuais previstas no Termo e demais obrigações decorrentes do uso dos nossos serviços e funcionalidades, inclusive para atendimento de disposições legais e regulatórias;</li>
                          <li>Possibilitar a criação de uma Conta de Acesso para utilização de nossos serviços e funcionalidades, inclusive para realização de simulações em Nossos Ambientes;</li>
                          <li>Atender adequadamente às suas solicitações e dúvidas;</li>
                          <li>Aperfeiçoar o uso e a experiência interativa durante sua navegação em Nossos Ambientes;</li>
                          <li>Promover nossos serviços, além de lhe informar sobre novidades, funcionalidades, conteúdos, notícias e demais informações relevantes para <strong>Você</strong>;</li>
                          <li>Garantir a portabilidade dos Dados cadastrais para outro Controlador do mesmo ramo de nossa atuação, caso solicitado por <strong>Você</strong>, cumprindo com obrigação do artigo 18 da Lei Geral de Proteção de Dados Pessoais;</li>
                          <li>Proteger <strong>Você</strong> no que diz respeito à prevenção de fraudes e riscos associados, além do cumprimento de obrigações legais e regulatórias;</li>
                        </div>
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <p><strong>Dados de mídias</strong></p>
                      </div>
                      <div>
                        <div>
                          <div>
                            <p>Áudios, fotos e vídeos</p>
                          </div>
                        </div>
                        <div>
                          <li>Possibilita a utilização por <strong>Você </strong>da funcionalidade relativa ao compartilhamento de mídias em Nossos Ambientes;</li>
                        </div>
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <p><strong>Dados de identificação digital</strong></p>
                      </div>
                      <div>
                        <div>
                          <div>
                            <p><em>ID</em> da Sessão</p>
                          </div>
                          <div>
                            <p>Endereço <em>IP</em> e Porta Lógica de Origem</p>
                          </div>
                          <div>
                            <p>Informações de dispositivo (tipo do dispositivo, conexões de rede, nome do dispositivo, versão do sistema operacional e conexão de <em>internet</em> utilizada)</p>
                          </div>
                          <div>
                            <p>Geolocalização</p>
                          </div>
                          <div>
                            <p>Registros de data e horário de cada ação que <strong>Você</strong> realizar</p>
                          </div>
                          <div>
                            <p>Quais telas <strong>Você</strong> acessou</p>
                          </div>
                          <div>
                            <p><em>Cookies</em></p>
                          </div>
                        </div>
                        <div>
                          <li>Identificar e autentificar <strong>Você</strong>;</li>
                          <li>Efetuar estatísticas, estudos, pesquisas e levantamentos relacionados ao comportamento do Usuário em Nossos Ambientes, realizando tais operações de forma anonimizada;</li>
                          <li>Corrigir problemas de tecnologia;</li>
                          <li>Proteger <strong>Você</strong> no que diz respeito à prevenção de fraudes e riscos associados, além do cumprimento de obrigações legais e regulatórias; e</li>
                          <li>Cumprir com obrigações legais de manutenção de registros estabelecidas pelo Marco Civil da Internet - Lei 12.965/2014.</li>
                        </div>
                      </div>
                    </ol>
                  </div>

                  <li>Para finalidade de prevenção a fraudes e garantia da autenticidade dos Dados fornecidos, <strong>Nós</strong> poderemos solicitar outros Dados Pessoais adicionais, não previstos na tabela acima, para confirmação das informações fornecidas pelos Usuários. Neste caso, <strong>Nós</strong> poderemos entrar em contato diretamente com <strong>Você</strong> para efetuar a requisição.</li>
                  <li><strong>Dados necessários.</strong> A prestação dos nossos serviços e funcionalidades disponíveis em Nossos Ambientes dependem diretamente de determinados Dados informados na tabela acima, em especial, dos Dados cadastrais para criação de sua Conta de Acesso. Caso <strong>Você</strong> opte por não fornecer alguns desses Dados, poderemos ficar impossibilitados de executar parte de nossos serviços e funcionalidades em Nossos Ambientes.</li>
                  <li>
                    <strong>Atualização e veracidade dos Dados.</strong> <strong>Você</strong> é o único responsável pela precisão, veracidade ou falta dela em relação aos Dados que <strong>Você</strong> fornece ou pela sua desatualização. Fique atento pois é de sua responsabilidade garantir a exatidão ou mantê-los atualizados.
                    <ol type="1">
                      <li>Da mesma forma, <strong>Nós</strong> não somos obrigados a processar ou tratar quaisquer dos seus Dados se houver razões para crer que tal processamento ou Tratamento possa nos imputar infração da legislação aplicável, ou se <strong>Você</strong> estiver utilizando Nossos Ambientes para quaisquer fins ilegais, ilícitos ou contrários à moralidade.</li>
                    </ol>
                  </li>
                  <li><strong>Base de Dados.</strong> A base de Dados, de propriedade da Instituição de Ensino, formada por meio da coleta de Dados, estará sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos dos negócios descritos nesta Política.</li>
                  <li>
                    <strong>Tecnologias empregadas.</strong> <strong>Nós</strong> utilizamos a(s) seguinte(s) tecnologia(s):
                    <ol type="1">
                      <ol type="i">
                        <li><em>Cookies</em>, utilizados para melhorar a funcionalidade do Portal ClassApp. A qualquer tempo, <strong>Você</strong> pode bloquear o uso de <em>Cookies</em> através das configurações o seu navegador de <em>Internet</em>, hipótese em que algumas funcionalidades do Portal ClassApp poderão ser limitadas;</li>
                        <li><em>Tags </em>de <em>Pixel</em>, possibilitam a coleta de informações (arquivos gravados em seu computador) referentes ao seu histórico de navegação, facilitam seu <em>login</em> (mediante armazenamento de suas credenciais) e promovem a interação entre <strong>Você</strong> e os serviços oferecidos por <strong>Nós</strong>. As <em>Tags</em> de <em>Pixel</em> também poderão ser desabilitadas mediante configurações das opções existentes em seu navegador de <em>Internet</em>;</li>
                        <li><em>Log files</em>, registram atividades referentes ao uso de nosso Portal ClassApp, tais como acesso a outros <em>websites</em>, o tipo e a qualidade de conexão de <em>internet</em>, <em>hardwares</em> ou <em>softwares</em> utilizados e as funcionalidades de determinado serviço que tenha sido utilizado por <strong>Você</strong>. Os <em>log files</em> poderão ser utilizados para monitorar o tráfego no Portal ClassApp e solucionar problemas técnicos;</li>
                        <li><em>Web beacons</em>, usados para rastrear anonimamente os seus padrões de uso <em>online</em>, a exemplo de <em>e-mails</em> abertos e <em>links</em> clicados por <strong>Você</strong>. As informações coletadas poderão identificar <em>websites</em>, serviços, produtos e funcionalidades populares, as quais serão utilizadas para personalizar nossos serviços para <strong>Você</strong>;</li>
                        <li><em>Google Analytics</em>, para registro de dados de navegação não identificáveis para análise;</li>
                        <li><em>Adwords</em> e <em>Facebook</em>, para registro de eventos durante a navegação;</li>
                        <li><em>Smartlook</em>, usado para entrega de relatórios de monitoramento completos sobre o comportamento do usuário em determinada interface; e</li>
                        <li><em>Hotjar</em>, utilizado para criação de “mapa de calor”, o qual indica onde as pessoas mais clicam e até onde rolam a página.</li>
                      </ol>
                      <li><strong>Nós</strong> também poderemos utilizar ferramentas fornecidas por empresas terceiras, as quais registrarão as atividades de interação do Usuário com Nossos Ambientes. O Tratamento destes Dados será realizado de forma anônima, com a finalidade de melhorar a experiência e conteúdo de Nossos Ambientes, observando-se o disposto na cláusula 5.6. abaixo.</li>
                      <li>Todas as tecnologias utilizadas respeitarão sempre a legislação vigente e os termos desta Política.</li>
                    </ol>
                  </li>
                  <li style={{ border: '1px solid black', padding: '1rem 1rem 1rem 3.9rem', marginLeft: '-3.9rem' }}><strong>Nós</strong> não utilizamos nenhum tipo de decisão unicamente automatizada que impacte <strong>Você</strong>.</li>
                </ol>
              </li>

              <li>
                <strong>COMO COMPARTILHAMOS DADOS E INFORMAÇÕES</strong>
                <ol type="1">
                  <li>
                    <strong>Hipóteses de compartilhamento dos Dados.</strong> Os Dados coletados e as atividades registradas podem ser compartilhados, sempre respeitando o envio do mínimo de informações necessárias para atingir as finalidades:
                    <ol type="i">
                      <li>Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determinação legal, requerimento, requisição ou ordem judicial;</li>
                      <li>Com empresas parceiras e prestadores de serviços necessários à execução dos nossos serviços e funcionalidades, sempre exigindo de tais organizações o cumprimento das diretrizes de segurança e proteção de dados, conforme item 5.6 desta Política; e</li>
                      <li>De forma automática, em caso de movimentações societárias, como fusão, aquisição e incorporação.</li>
                    </ol>
                  </li>
                  <li><strong>Anonimização de Dados.</strong> Para as finalidades de pesquisas de inteligência de mercado, divulgação de dados à imprensa, realização de propagandas e outras atividades aplicáveis, os dados fornecidos por <strong>Você</strong> serão compartilhados de forma anonimizada, isto é, de forma que não possibilite a sua identificação.</li>
                </ol>
              </li>

              <li>
                <strong>COMO PROTEGEMOS SEUS DADOS E COMO VOCÊ TAMBÉM PODERÁ PROTEGÊ-LOS</strong>
                <ol type="1">
                  <li>
                    <strong>Medidas que tomamos.</strong> <strong>Nós</strong> empregamos os melhores esforços para manter a privacidade e a segurança das informações por meio da adoção de medidas de segurança técnicas, físicas e administrativas:
                    <ol type="i">
                      <li><strong>medidas técnicas</strong>, como por exemplo transmissão de Dados Pessoais por meio de página de <em>internet </em>segura, armazenamento de Dados em meios eletrônicos que mantêm altos padrões de segurança, utilização de sistema cujo acesso é controlado;</li>
                      <li><strong>medidas físicas</strong>, tais como acesso restrito a pessoas autorizadas mantido em instalações que incluem, uso de ferramentas de segurança de mercado; e</li>
                      <li><strong>medidas administrativas</strong>, incluindo a adoção de Políticas e Normas de Segurança, treinamentos/conscientização de colaboradores, acordos de confidencialidade.</li>
                    </ol>
                  </li>
                  <li><strong>Compartilhamento de senhas.</strong> <strong>Você</strong> também é responsável pelo sigilo de seus Dados Pessoais e deve ter sempre ciência de que o compartilhamento de senhas e dados de acesso viola esta Política e pode comprometer a segurança dos seus Dados e dos Nossos Ambientes.</li>
                  <li><strong>Cuidados que <strong>Você</strong> dever tomar.</strong> É muito importante que <strong>Você</strong> proteja seus Dados contra acesso não autorizado ao seu dispositivo, conta ou senha. Nossos contatos têm a finalidade de trazer informações sobre suas dúvidas e solicitações, bem como demais informações relevantes para <strong>Você</strong>.</li>
                  <li><strong>Acesso aos Dados Pessoais, proporcionalidade e relevância.</strong> Internamente, os Dados Pessoais coletados são acessados somente por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade, necessidade e relevância para os objetivos do nosso negócio, além do compromisso de confidencialidade e preservação da sua privacidade disposto na presente Política. Profissionais e/ou pessoas autorizadas que se utilizarem indevidamente desses Dados, violando os termos desta Política, estarão sujeitos às penalidades previstas em nosso processo disciplinar, sem exclusão das demais penalidades legais aplicáveis.</li>
                  <li>
                    <strong><em>Links </em>externos.</strong> Quando <strong>Você</strong> utilizar Nossos Ambientes, <strong>Você</strong> poderá ser conduzido, via <em>link</em> a outros portais ou plataformas, inclusive ambientes pertencentes a terceiros prestadores de serviços das Instituições de Ensino, como resultado da integração de Nossos Ambientes com ambientes de terceiros requisitada pela Instituição de Ensino, podendo estes coletar suas informações e ter sua própria Política de Tratamento de Dados.
                    <ol type="1">
                      <li>Caberá a <strong>Você</strong> ler a Políticas de Privacidade e Tratamento de Dados de tais portais ou plataformas fora de Nossos Ambientes, sendo de sua responsabilidade aceitá-la ou rejeitá-la. <strong>Nós</strong> não somos responsáveis pelas Políticas de Privacidade e Tratamento de Dados de terceiros e nem pelo conteúdo de quaisquer <em>websites,</em> conteúdo dos serviços ligados à ambientes que não os nossos.</li>
                      <li>Serviços de parceiros. <strong>Nós</strong> possuímos parceiros comerciais que, eventualmente, podem oferecer serviços por meio de funcionalidades ou <em>websites</em> que podem ser acessados a partir de Nossos Ambientes. Os Dados fornecidos por <strong>Você</strong> a estes parceiros serão de responsabilidade destes, estando assim sujeitos às suas próprias práticas de coleta e uso de dados.</li>
                    </ol>
                  </li>
                  <li><strong>Processamento por terceiros sob nossa diretriz.</strong> Caso empresas terceirizadas realizem o Tratamento em nosso nome de quaisquer Dados Pessoais que coletamos, as mesmas respeitarão as condições aqui estipuladas e as normas de segurança da informação, obrigatoriamente.</li>
                  <li>
                    <strong>Comunicação por <em>e-mail</em>.</strong> Para otimizar e melhorar nossa comunicação, quando enviamos um <em>e-mail</em> para <strong>Você</strong> podemos receber uma notificação quando eles são abertos, desde que esta possibilidade esteja disponível. <strong>Nós</strong> não utilizamos serviços de terceiros para enviar <em>e-mails</em> em nosso nome. É importante <strong>Você</strong> ficar atento, pois os e-mails são enviados somente pelos domínios: @classapp.com.br; @clsp.co; ou @classapp.co.
                    <ol type="1">
                      <li>Caso <strong>Você</strong> receba um <em>e-mail</em> que acredita não ter sido enviado por <strong>Nós</strong>, <strong>Você</strong> deverá se abster de adotar qualquer ação e deverá entrar em contato conosco imediatamente por meio de nossos Canais de Atendimento, a fim de confirmar sua veracidade.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>COMO ARMAZENAMOS SEUS DADOS PESSOAIS E O REGISTRO DE ATIVIDADES</strong>
                <ol type="1">
                  <li>Os Dados Pessoais coletados e os registros de atividades são armazenados em ambiente seguro e controlado por um prazo mínimo que segue a tabela abaixo:</li>

                  <div className="simple-table">
                    <div>
                      <div>
                        <p style={{ textAlign: 'center' }}><strong>PRAZO DE ARMAZENAMENTO</strong></p>
                      </div>
                      <div>
                        <p style={{ textAlign: 'center' }}><strong>FUNDAMENTO LEGAL</strong></p>
                      </div>
                    </div>

                    <div>
                      <div>
                        <p style={{ textAlign: 'center' }}>Enquanto durar a relação e não houver pedido de apagamento ou revogação de consentimento</p>
                      </div>
                      <div>
                        <p style={{ textAlign: 'center' }}>Art. 9º, inciso II da Lei Geral de Proteção de Dados Pessoais</p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p style={{ textAlign: 'center' }}>3 anos após o término da relação</p>
                      </div>
                      <div>
                        <p style={{ textAlign: 'center' }}>Art. 206, § 3º, inciso V do Código Civil</p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p style={{ textAlign: 'center' }}>6 meses para os Dados de Identificação Digital</p>
                      </div>
                      <div>
                        <p style={{ textAlign: 'center' }}>Art. 15 do Marco Civil da <em>Internet</em></p>
                      </div>
                    </div>
                  </div>

                  <li><strong>Prazos de armazenamento superiores.</strong> Para fins de auditoria, segurança, controle de fraudes e preservação de direitos poderemos permanecer com o histórico de registro de seus Dados por prazo maior nas hipóteses que a lei ou norma regulatória, assim estabelecer ou para preservação de direitos.</li>
                  <li style={{ border: '1px solid black', padding: '1rem 1rem 1rem 3.9rem', marginLeft: '-3.9rem' }}>Os Dados coletados serão armazenados em nossos servidores localizados no Brasil, bem como em ambiente de uso de recursos ou servidores na nuvem (<em>cloud computing</em>), <strong>o que poderá exigir uma transferência e/ou processamento destes Dados fora do Brasil.</strong></li>
                </ol>
              </li>

              <li>
                <strong>QUAIS SÃO OS SEUS DIREITOS E COMO EXERCÊ-LOS</strong>
                <ol type="1">
                  <li><strong>Seus direitos básicos.</strong> <strong>Você</strong> poderá solicitar a confirmação da existência de Tratamento de Dados Pessoais, além da exibição ou retificação de seus Dados Pessoais, por meio dos Canais de Atendimento, nos termos do item “8.4”.</li>
                  <li>
                    <strong>Limitação, oposição e exclusão de dados.</strong> Pelos Canais de Atendimento, <strong>Você</strong> poderá também requerer:
                    <ol type="1">
                      <ol type="i">
                        <li>A limitação do uso de seus Dados Pessoais;</li>
                        <li>Manifestar sua oposição e/ou revogar o consentimento quanto ao uso de seus Dados Pessoais; ou</li>
                        <li>Solicitar a exclusão de seus Dados Pessoais que tenham sidos coletados por <strong>Nós</strong>.</li>
                      </ol>
                      <li>Caso <strong>Você</strong> solicite a exclusão de seus Dados Pessoais, pode ocorrer que os Dados precisem ser mantidos por período superior ao pedido de exclusão, nos termos do artigo 16 da Lei Geral de Proteção de Dados Pessoais, para (i) cumprimento de obrigação legal ou regulatória, (ii) estudo por órgão de pesquisa, e (iii) transferência a terceiro (respeitados os requisitos de Tratamento de dados dispostos na mesma Lei). Em todos os casos mediante a anonimização dos Dados Pessoais, desde que possível.</li>
                      <li>Findos o prazo de manutenção e a necessidade legal, os Dados Pessoais serão excluídos com uso de métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <strong>INFORMAÇÕES SOBRE ESSA POLÍTICA</strong>
                <ol type="1">
                  <li>
                    <strong>Alteração do teor e atualização.</strong> <strong>Você</strong> reconhece o nosso direito de alterar o teor desta Política a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo a <strong>Você</strong> verificá-la sempre que efetuar o acesso aos Nossos Ambientes ou utilizar nossos serviços e funcionalidades.
                    <ol type="1">
                      <li>Ocorrendo atualizações neste documento e que demandem nova coleta de consentimento, <strong>Você</strong> poderá ser notificado por meio dos canais de contatos que <strong>Você</strong> informar ou através de seu acesso em Nossos Ambientes.</li>
                      <li>Caso <strong>Você</strong> não concorde com referidas alterações, <strong>Você</strong> poderá nos solicitar o cancelamento de sua Conta de Acesso, ficando ciente das implicações de sua ação, em especial, da impossibilidade de utilização de nossos serviços e funcionalidades disponíveis em Nossos Ambientes.</li>
                    </ol>
                  </li>
                  <li><strong>Inaplicabilidade.</strong> Caso algum ponto desta Política seja considerado inaplicável por Autoridade de Dados ou judicial, as demais condições permanecerão em pleno vigor e efeito.</li>
                  <li><strong>Comunicação eletrônica.</strong> <strong>Você</strong> reconhece que toda comunicação realizada por <em>e-mail</em> (aos endereços informados no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra forma digital, também são válidas, eficazes e suficiente para a divulgação de qualquer assunto que se refira às funcionalidades que oferecemos em Nossos Ambientes, aos seus Dados, bem como qualquer outro assunto abordado, sendo exceção apenas o que essa Política prever como tal.</li>
                  <li>
                    <strong>Canais de Atendimento.</strong> Em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade e Tratamento de Dados, <strong>Você</strong> poderá entrar em contato por meio dos Canais de Atendimento apontados a seguir:
                    <ol type="i">
                      <li>
                        Canais para dúvidas:
                        <p><strong>Chat <em>Online</em></strong> ferramenta disponível no Portal ClassApp para envio de mensagens instantâneas, cujo horário de funcionamento é de segunda - feira a sexta - feira, das 09 horas às 17 horas, exceto em feriados adotados por <strong>Nós</strong>;</p >
                        <p><strong>Envie uma Solicitação</strong> através da aba “Ajuda” disponível em Nossos Ambientes, será possível nos enviar uma mensagem contendo sua solicitação; e</p >
                        <p><strong>Envio de correspondência física</strong> Rua Carlos Gomes, 1321, 8º andar, sala 801 a 808, Centro, Limeira – SP, CEP: 13.480 - 013.</p >
                      </li>
                      <li>Canal para contato com o Encarregado de Dados: <a href="mailto:privacidade@classapp.com.br" rel="noopener noreferrer" target="_blank">privacidade@classapp.com.br</a></li>
                    </ol>
                  </li>
                  <li><strong>Lei aplicável e foro.</strong> Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro da Comarca de Limeira, Estado de São Paulo, para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.</li>
                </ol>
              </li>
            </ol >
            <br />
          </section >

        }
      </div>
    );
  }
}
